import * as Yup from 'yup';
import i18n from '../../../../../../../i18n';

export const validationSchema = Yup.object().shape({
  contract_uuid: Yup.string()
    .nullable()
    .test('name_ord', i18n.t('errors.err45'), (value) => {
      if (value === undefined || !value) {
        return false;
      }
      return true;
    }),
  kktu_codes: Yup.string().test(
    'kktu_codes',
    i18n.t('errors.err_value'),
    (value) => {
      const regex = /^(\d{1,2}\.\d{1,2}\.\d{1,2})$/g;
      if (value === undefined) return true;
      if (value) {
        return regex.test(value);
      }
      return false;
    },
  ),
  ord_name: Yup.string()
    .nullable()
    .test('name_ord', i18n.t('errors.err45'), (value) => {
      if (value === undefined || !value) {
        return false;
      }
      return true;
    }),
});
