import React, { FC, RefObject, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { TCampaignsFilter } from 'domains/campaigns/types';
import { FieldProps } from 'formik';
import { SelectField, SelectFieldWithKktu } from 'types/general';
import { GroupBase, Props as ReactSelectProps } from 'react-select';
import SelectWrapper from '../components/SelectWrapper';
import { toolTipType } from '../../Input/types';
import Label from '../../Input/Label';
import { ClearIndicator } from '../components/ClearIndicator';
import { DropdownIndicator } from '../components/DropdownIndicator';
import i18n from '../../../../i18n';
import ErrorText from '../../ErrorText';

interface OptionTypeBase {
  label: string;
  value: any;
}

interface ICreatableCustomSelectProps<
  Option extends OptionTypeBase,
  isMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends Omit<ReactSelectProps<Option, isMulti, Group>, 'options' | 'form'> {
  /** наличие ошибки */
  error?: boolean;
  /** Текст ошибки */
  errorText?: string;
  /** Скрывает иконку и компонента Error */
  errorHideIcon?: boolean;
  /** Получение функции для вывода в formik */
  form?: FieldProps['form'];
  /** Получение name от поля в formik */
  field?: FieldProps['field'];
  /** Атрибуты для Select */
  selectAttributes: ReactSelectProps<Option, boolean, Group>;
  /** Кстомные Option */
  customOptions?: ReactSelectProps<Option, false | true, Group>['components'];
  validateHandler?: (field: string) => void;
  /** Ref селекта */
  selectRef?: RefObject<any>;
  onChangeCallback?: {
    id: number;
    key: keyof TCampaignsFilter;
    callback: (id: number, value: string, k: keyof TCampaignsFilter) => void;
  };
  onCustomChange?: (value: SelectFieldWithKktu) => void;
  /** Автосохранение */
  autoSubmit?: boolean;
  /** label для select. Доступен только в  viewStyle="Vertical" */
  label?: string;
  /** Кастомынй класс */
  className?: string;
  /** Кастомный префикс для собственных стилей */
  classNamePrefix?: string;
  /** Строка результата поиска, когда ничего не найдено */
  noOptionsMessage?: (obj: { inputValue: string }) => string | null;
  setCurrentAudit?: React.Dispatch<React.SetStateAction<string>>;
  /** Стандартный вид */
  viewStyle: 'Vertical' | 'Horizontal';
  /** Высота селекта */
  heightCustom?: number;
  /** Выводит только выбранный Option */
  viewingOnly?: boolean;
  /** Тултип для label */
  labelTooltip?: toolTipType;
  /** Наличие крестика у multiOption */
  MultiValueRemove?: boolean;
}

export const CreatableCustomSelect: FC<
  ICreatableCustomSelectProps<OptionTypeBase>
> = ({
  form,
  field,
  error,
  errorText,
  onChangeCallback,
  selectAttributes,
  validateHandler,
  viewStyle = 'Vertical',
  customOptions,
  autoSubmit,
  selectRef,
  onCustomChange,
  label,
  classNamePrefix = 'new_select',
  className,
  noOptionsMessage = () => i18n.t(`no_result`),
  errorHideIcon = false,
  setCurrentAudit,
  heightCustom = 0,
  viewingOnly,
  labelTooltip,
  MultiValueRemove = false,
}:  ICreatableCustomSelectProps<OptionTypeBase>) => {
  const [isSave, setIsSave] = useState<boolean>(false);
  const [idTimeout, setIdTimeout] = useState<NodeJS.Timeout[]>([]);

  const onShowLight = () => {
    clearSaveTimeuots();
    let id = null;
    id = setTimeout(() => {
      setIsSave(true);
    }, 150);
    id = setTimeout(() => {
      setIsSave(false);
    }, 2000);
    setIdTimeout([...idTimeout, id]);
  };

  const clearSaveTimeuots = () => {
    idTimeout.forEach((item) => {
      clearTimeout(item);
    });
  };

  useEffect(() => {
    if (setCurrentAudit) {
      setCurrentAudit(field?.value);
    }
  }, [field]);

  const MultiValueRemoveCustom = MultiValueRemove
    ? {}
    : {
        MultiValueRemove: () => null,
      };

  return (
    <SelectWrapper
      classname={className}
      heightCustom={heightCustom}
      isError={error && errorText !== ''}
      isDisabled={selectAttributes?.isDisabled}
      isVerticalStyle={viewStyle === 'Vertical'}
      isOnlyView={viewingOnly}
      isSave={isSave}
    >
      {label && viewStyle === 'Vertical' && (
        <Label
          label={label}
          forInput={selectAttributes?.id}
          tooltip={labelTooltip}
        />
      )}
      <CreatableSelect
        ref={selectRef}
        classNamePrefix={classNamePrefix}
        components={{
          ClearIndicator,
          DropdownIndicator,
          IndicatorSeparator: null,
          ...MultiValueRemoveCustom,
          ...customOptions,
        }}
        {...selectAttributes}
        placeholder={
          selectAttributes.placeholder ||
          i18n.t(`creative.create_creative.Please_select`)
        }
        onChange={(option) => {
          if (onChangeCallback && option) {
            const optionData = option as unknown as SelectField;
            const { key, id, callback } = onChangeCallback;
            callback(id, optionData.value, key);
          }
          if (selectAttributes.isMulti) {
            const options = option as unknown as SelectField[];
            form?.setFieldValue(
              `${field?.name}`,
              options.map(({ value }) => value).join(','),
            );
          } else {
            const optionData = option as unknown as SelectField;
            if (onCustomChange && optionData) {
              onCustomChange(optionData);
            }
            if (form && field) {
              form
                .setFieldValue(
                  `${field.name}`,
                  optionData ? optionData.label : '',
                )
                .then(() => {
                  if (validateHandler) validateHandler(field.name);
                  if (autoSubmit) {
                    form.handleSubmit();
                    onShowLight();
                  }
                });
            }
          }
        }}
        tabSelectsValue={false}
        closeMenuOnSelect={!selectAttributes.isMulti}
        hideSelectedOptions={!selectAttributes.isMulti}
        noOptionsMessage={noOptionsMessage}
        menuPortalTarget={document.getElementById('select-body-portal')}
        formatCreateLabel={(inputValue) =>
          `${i18n.t(`create_btn`)} ${inputValue}`
        }
      />
      {error && errorText !== '' && (
        <ErrorText text={errorText} hideIcon={errorHideIcon}/>
      )}
    </SelectWrapper>
  );
};
