import Tooltip from 'components/UI/Tooltip';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FiHelpCircle } from 'react-icons/fi';
import { checkedLocalization } from 'utils/checkedLocalization';
import { Link } from 'react-router-dom';
import Help from 'components/Help';
import { TargetKey } from 'domains/target/model/types';
import css from '../styles.module.scss';

interface Props {
  type: TargetKey;
  downloadExelCatalog: () => void;
}

const ModalHeader: FC<Props> = ({ type, downloadExelCatalog }) => {
  const { t } = useTranslation();
  const gray = { color: '#828282' };

  return (
    <div className={css.label}>
      <span>
        {checkedLocalization(
          `targetings.add_popap_tit.add_popap_smalltit.${type}`,
          `targetings.add_popap_tit.add_popap_smalltit.general`,
        )}
      </span>
      {type === 'site_uri' && (
        <Help
          title=""
          helpattributes={{
            text: t(`common.targetings_error.site_uri.help`),
          }}
        />
      )}
      {type === 'site_domain' && (
        <Help
          title=""
          helpattributes={{
            text: t(`common.targetings_error.site_domain.help`),
          }}
        />
      )}
      {(type === 'au' || type === 'adgroup' || type === 'private_deals') && (
        <Tooltip
          title={<FiHelpCircle size={16} color="#0a0a0a" />}
          white
          hintWidth={300}
        >
          <p>{t(`targetings.add_popap_tit.add_popap_prompt.title.${type}`)}</p>
        </Tooltip>
      )}
      {type === 'geo_id' && (
        <Tooltip
          title={<FiHelpCircle size={16} color="#0a0a0a" />}
          white
          hintWidth={400}
        >
          <p>
            {t(
              `targetings.add_popap_tit.add_popap_prompt.title.${type}.sever_match`,
            )}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <span className={css.link} onClick={downloadExelCatalog}>
              {t(
                `targetings.add_popap_tit.add_popap_prompt.title.${type}.partTwo`,
              )}
            </span>
          </p>
        </Tooltip>
      )}
      {type === 'au_self' && (
        <Tooltip
          title={<FiHelpCircle size={16} color="#0a0a0a" />}
          white
          hintWidth={400}
        >
          <p>{t(`targetings.au.add_several`)}</p>
        </Tooltip>
      )}
      {type === 'geo_raw' && (
        <Tooltip
          title={<FiHelpCircle size={16} color="#0a0a0a" />}
          white
          hintWidth={400}
        >
          <p>
            {t(
              `targetings.add_popap_tit.add_popap_prompt.title.${type}.partOne`,
            )}
            <Link
              to={{
                pathname:
                  'https://terratraf.readme.io/docs/td-target-countrylist',
              }}
              target="_blank"
            >
              {t(
                `targetings.add_popap_tit.add_popap_prompt.title.${type}.linkOne`,
              )}
            </Link>

            {t(
              `targetings.add_popap_tit.add_popap_prompt.title.${type}.partTwo`,
            )}
            <Link
              to={{
                pathname: 'https://terratraf.readme.io/docs/td-target-citylist',
              }}
              target="_blank"
            >
              {t(
                `targetings.add_popap_tit.add_popap_prompt.title.${type}.linkTwo`,
              )}
            </Link>
          </p>
        </Tooltip>
      )}
      {type === 'app' && (
        <Tooltip title={<FiHelpCircle size={16} color="#0a0a0a" />} white>
          <p>{t(`targetings.add_popap_tit.add_popap_prompt.title.${type}`)}</p>
        </Tooltip>
      )}
      {type === 'browser_lang' && (
        <Tooltip
          title={<FiHelpCircle size={16} color="#0a0a0a" />}
          white
          hintWidth={300}
        >
          <p>{t(`targetings.add_popap_tit.add_popap_prompt.title.${type}`)}</p>
          <p>
            <span style={gray}>
              {t(`targetings.add_popap_tit.add_popap_prompt.text.${type}`)}
            </span>
          </p>
        </Tooltip>
      )}
    </div>
  );
};

export default ModalHeader;
