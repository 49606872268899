import React, { FC } from 'react';
import CardList from 'components/UI/CardList';
import { Form, FormikProvider } from 'formik';
import { useIsGroup } from 'domains/user/hooks';
import i18n from '../../../../../i18n';
import { useCreateCampaignForm } from './hooks/useCreateCampaignForm';
import Period from '../components/Period';
import css from '../../../../campaigns/CreateCampaign/Settings/styles.module.scss';
import Button from '../../../../../components/UI/Buttons/buttons';
import Optimization from '../components/Optimization';
import Limits from '../components/Limits';
import AdvancedOptimization from '../components/AdvancedOptimization';
import Container from '../components/Container';
import Campaign from '../components/Campaign';
import CampaignInGrid from '../components/CampaignInGrid';

const CreateCampaign: FC = () => {
  const { isOwner, isSelfUser } = useIsGroup();
  const {
    formik,
    onKeyDown,
    goBack,
    isLoading,
    currency,
    isRawStat,
    isTest,
    advancedList,
    handlerUpdateTags,
    clients,
    tags,
    clientDisabled,
    experimentalInfo,
    offersOptions,
    contractsOptions,
  } = useCreateCampaignForm();
  return (
    <CardList>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown}>
          <CampaignInGrid
            formik={formik}
            campaign={null}
            type="CREATE"
            contractsOptions={contractsOptions}
          />
          <Campaign
            formik={formik}
            handlerUpdateTags={handlerUpdateTags}
            tags={tags}
            clients={clients}
            setField={formik.setFieldValue}
            campaign={null}
            clientDisable={clientDisabled}
            offersOptions={offersOptions}
            titleAutoFocus
          />
          <Period formik={formik} />
          {!isSelfUser && (
            <Optimization
              formik={formik}
              currency={currency}
              setField={formik.setFieldValue}
              experimentalInfo={experimentalInfo}
            />
          )}
          <Limits
            formik={formik}
            currency={currency}
            isRawStat={isRawStat}
            isTest={isTest}
          />
          {isOwner && (
            <AdvancedOptimization
              formik={formik}
              advancedList={advancedList}
              setField={formik.setFieldValue}
            />
          )}
          <Container
            campaign={null}
            formik={formik}
            setField={formik.setFieldValue}
          />
          <div className={css.actions}>
            <Button
              title={i18n.t('campaigns_page.create_campaign.cancel_btn')}
              transparent
              height={40}
              buttonAttributes={{
                type: 'button',
                onClick: () => goBack(),
              }}
            />
            <Button
              title={i18n.t('campaigns_page.create_campaign.create_btn')}
              height={40}
              buttonAttributes={{
                type: 'submit',
                disabled: !formik.isValid || isLoading,
              }}
            />
          </div>
        </Form>
      </FormikProvider>
    </CardList>
  );
};
export default CreateCampaign;
