import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import css from './styles.module.scss';
import { toolTipType } from '../Input/types';
import Label from '../Input/Label';

interface Props {
  label?: string;
  checked: boolean;
  disabled?: boolean;
  activateText?: string | ReactNode;
  deactivateText?: string | ReactNode;
  onChange?: () => void;
  notField?: boolean;
  className?: string;
  toggleOnly?: boolean;
  /** Отключает подцветку Активного положения */
  hideToggleBgActive?: boolean;
  /** Вывод тултипа со ссылкой */
  tooltip?: toolTipType;
  /* Мини версия */
  mini?: boolean;
  isSaved?: boolean;
  /** Если текст одинаковый для всех состояний */
  oneText?: string | ReactNode;
}

const ToggleSwitch: FC<Props> = ({
  label,
  checked,
  disabled,
  activateText,
  deactivateText,
  onChange,
  notField,
  toggleOnly,
  className,
  hideToggleBgActive = false,
  tooltip,
  mini,
  isSaved,
  oneText = '',
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={cn(
        css['form-group'],
        'switch',
        { _deactive: notField, mini },
        className,
      )}
    >
      {label && <Label label={label} tooltip={tooltip} />}
      <div
        className={cn(css.switch, 'switch_body', {
          _isActive: checked && !hideToggleBgActive,
          _isActiveNoBg: checked && hideToggleBgActive,
          _isDisabled: disabled,
        })}
        onClick={onChange}
      >
        <div className={cn(css.toggle, 'toggle')}>
          <div className={css.button} />
        </div>
        {!toggleOnly && (
          <div className={cn(css.text, 'text', { isSaved })}>
            <span>
              {checked &&
                oneText === '' &&
                (activateText || t('campaigns_page.campaign_settings.Running'))}
              {!checked &&
                oneText === '' &&
                (deactivateText ||
                  t('campaigns_page.campaign_settings.Paused'))}
              {oneText !== '' && oneText}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ToggleSwitch;
