import InputAutoSave from 'components/UI/InputAutoSave';
import LabelField from 'components/UI/LabelField';
import NewSelect, { OptionTypeBase } from 'components/UI/NewSelect';
import { CreatableCustomSelect } from 'components/UI/NewSelect/CreatableCustomSelect';
import { RGetPersonal, TCampaignForm } from 'domains/campaign/types';
import { useClientInfo } from 'domains/client/model/selectors';
import { useProjectInfo } from 'domains/project/hooks';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';
import { useIsGroup } from 'domains/user/hooks';
import { useUserInfo } from 'domains/user/model/selectors';
import { Field, FieldProps, FormikProps } from 'formik';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { isFieldInObject } from 'utils/isFieldInObject';
import cn from 'classnames';
import { SelectField } from 'types/general';
import { Element } from 'react-scroll';
import { PropsValue } from 'react-select';
import { useGridCampaignForContract } from 'domains/campaign/model/selectors';
import Load from 'components/UI/Load';
import css from '../../styles.module.scss';
import ContractField from './ContractField';
import i18n from '../../../../../../../i18n';
import { showContractField, showKktuField, showOrdNameField } from './utils';

type TFieldsWithConditionProps = {
  campaign: RGetPersonal | null;
  type: 'EDIT' | 'CREATE';
  formik: FormikProps<TCampaignForm>;
  contractsOptions: SelectField[] | undefined;
  handlerInput?: (() => void) | undefined;
  savedField?: Record<string, unknown> | undefined;
  locked?: boolean | undefined;
};

const FieldsWithCondition: FC<TFieldsWithConditionProps> = ({
  campaign,
  type,
  formik,
  contractsOptions,
  handlerInput,
  savedField,
  locked,
}) => {
  const ordNameSelectOptions = useMemo(
    () => [
      {
        label: i18n.t('select_btn'),
        value: 'pick',
      },
      {
        label: i18n.t('create_btn'),
        value: 'create',
      },
    ],
    [i18n.language],
  );
  const { data: projectData } = useProjectInfo();
  const { isOwner, isOrdOwner } = useIsGroup();
  const { data: partner } = useClientInfo();
  const location = useLocation();
  const { data: breadcrumbs } = useSearchBreadcrumbsInfo();
  const { data: user } = useUserInfo();
  const {
    data: gridCampaignForContract,
    isLoading: isGridCampaignForContractLoading,
  } = useGridCampaignForContract();
  const [nameCampaignInOrd, setNameCampaignInOrd] = useState<
    OptionTypeBase[] | null
  >(null);

  const [createOrdName, setCreateOrdName] = useState<boolean>(true);
  const [defaultOrdNameType, setDefaultOrdNameType] = useState<
    PropsValue<OptionTypeBase>
  >(ordNameSelectOptions[1]);

  useEffect(() => {
    if (gridCampaignForContract) {
      const data = gridCampaignForContract.map((obj) => ({
        label: obj.ord_name,
        value: obj.uuid,
        kktu_codes: obj.kktu_codes,
      }));
      setNameCampaignInOrd(data);
    }
  }, [gridCampaignForContract]);

  return (
    <>
      {type === 'CREATE' && (
        <>
          {showContractField({ projectData, partner, breadcrumbs, user }) && (
            <ContractField
              formik={formik}
              contractsOptions={contractsOptions}
              type={type}
            />
          )}
          {showOrdNameField({
            projectData,
            partner,
            breadcrumbs,
            isOwner,
            formik,
            user,
          }) && (
            <LabelField
              label={i18n.t(`campaigns_page.create_campaign.ord_name`)}
              classname={cn(css.field, {
                _isError: !!formik.errors.ord_name && !createOrdName,
              })}
            >
              <div className={css.createNameOrd}>
                <NewSelect
                  selectAttributes={{
                    placeholder: i18n.t(`ord.customer_select`),
                    options: ordNameSelectOptions,
                    value: defaultOrdNameType,
                  }}
                  viewStyle="Horizontal"
                  heightCustom={32}
                  onCustomChange={(val) => {
                    const currentEl = ordNameSelectOptions.find(
                      (option) => option.value === val,
                    );
                    formik.setFieldValue('ord_name', '');
                    setDefaultOrdNameType(currentEl || ordNameSelectOptions[1]);
                    return val === 'create'
                      ? setCreateOrdName(true)
                      : setCreateOrdName(false);
                  }}
                />
                {createOrdName && (
                  <InputAutoSave
                    name="ord_name"
                    label=""
                    placeholder={i18n.t(`offers_page.enter_name`)}
                    value={formik.values.ord_name || ''}
                    handlerInputEnter={handlerInput}
                    setFieldValue={formik.setFieldValue}
                    isError={!!formik.errors.ord_name}
                    errorText={formik.errors.ord_name}
                    isSaved={isFieldInObject(savedField, 'ord_name')}
                    isDisable={locked}
                  />
                )}
                {!createOrdName && !isGridCampaignForContractLoading && (
                  <Field name="ord_name">
                    {({ form, field }: FieldProps) => (
                      <CreatableCustomSelect
                        field={field}
                        form={form}
                        error={!!formik.errors.ord_name}
                        errorText={formik.errors.ord_name}
                        autoSubmit={
                          !location.pathname.includes('create-campaign')
                        }
                        selectAttributes={{
                          isClearable: true,
                          options: nameCampaignInOrd ?? [],
                          defaultValue: campaign?.ord_name
                            ? {
                                label: campaign?.ord_name,
                                value: campaign?.ord_name,
                              }
                            : null,
                          onInputChange: (val) =>
                            val.length <= 150 ? val : val.substring(0, 150),
                        }}
                        onCustomChange={(val) => {
                          formik.setFieldValue('kktu_codes', val.kktu_codes);
                          formik.setFieldValue('uuid', val.value);
                        }}
                        viewStyle="Horizontal"
                        heightCustom={32}
                      />
                    )}
                  </Field>
                )}
                {!createOrdName && isGridCampaignForContractLoading && (
                  <Load
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                )}
              </div>
            </LabelField>
          )}
          {showKktuField({ user, partner }) && (
            <Element name="kktu_codes">
              <InputAutoSave
                name="kktu_codes"
                label={i18n.t(`campaigns_page.create_campaign.kktu`)}
                placeholder={i18n.t(`campaigns_page.create_campaign.kktu`)}
                value={formik.values.kktu_codes || ''}
                handlerInputEnter={handlerInput}
                setFieldValue={formik.setFieldValue}
                isError={!!formik.errors.kktu_codes}
                errorText={formik.errors.kktu_codes}
                isSaved={isFieldInObject(savedField, 'kktu_codes')}
                isDisable={locked}
              />
            </Element>
          )}
        </>
      )}
      {type === 'EDIT' && (
        <>
          {campaign?.campaign_grid?.uuid && (
            <>
              {showContractField({
                projectData,
                partner,
                breadcrumbs,
                user,
              }) && (
                <ContractField
                  formik={formik}
                  contractsOptions={contractsOptions}
                  type={type}
                />
              )}
              {showOrdNameField({
                projectData,
                partner,
                breadcrumbs,
                isOwner,
                formik,
                user,
              }) && (
                <LabelField
                  label={i18n.t(`campaigns_page.create_campaign.ord_name`)}
                >
                  <div className={cn(css.createNameOrd, '_idEditMode')}>
                    <Element name="ord_name">
                      <InputAutoSave
                        name="ord_name"
                        label=""
                        placeholder={i18n.t(
                          `campaigns_page.create_campaign.ord_name`,
                        )}
                        value={formik.values.ord_name || ''}
                        handlerInputEnter={handlerInput}
                        setFieldValue={formik.setFieldValue}
                        isError={!!formik.errors.ord_name}
                        errorText={formik.errors.ord_name}
                        isSaved={isFieldInObject(savedField, 'ord_name')}
                        isDisable={locked || !isOrdOwner}
                        isEmptyField
                      />
                    </Element>
                  </div>
                </LabelField>
              )}
              {showKktuField({ user, partner }) && (
                <Element name="kktu_codes">
                  <InputAutoSave
                    name="kktu_codes"
                    label={i18n.t(`campaigns_page.create_campaign.kktu`)}
                    placeholder={i18n.t(`campaigns_page.create_campaign.kktu`)}
                    value={formik.values.kktu_codes || ''}
                    handlerInputEnter={handlerInput}
                    setFieldValue={formik.setFieldValue}
                    isError={!!formik.errors.kktu_codes}
                    errorText={formik.errors.kktu_codes}
                    isSaved={isFieldInObject(savedField, 'kktu_codes')}
                    isDisable={locked}
                    isEmptyField
                  />
                </Element>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(FieldsWithCondition);
