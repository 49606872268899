import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { FieldProps } from 'formik';
import { useOuterElementClick } from '../../../hooks/useOuterElementClick';

type TFUseInputAutoSaveProps = {
  defaultValue?: string;
  value: string;
  handlerInputEnter?: () => void;
  name: string;
  setFieldValue: FieldProps['form']['setFieldValue'];
  isError: boolean;
  autoFocus?: boolean;
  data?: any;
  isEmptyField?: boolean;
};

type TFUseInputAutoSave = (param: TFUseInputAutoSaveProps) => {
  isModeEdit: boolean;
  isShowCreator: boolean;
  handlerShowInput: () => void;
  inputRef: RefObject<HTMLInputElement>;
  wrapperRef: ReturnType<typeof useOuterElementClick>['wrapperRef'];
  handlerOpenModeEdit: () => void;
};

export const useInputAutoSave: TFUseInputAutoSave = ({
  defaultValue,
  value = '',
  handlerInputEnter,
  name,
  isError,
  setFieldValue,
  autoFocus,
  data,
  isEmptyField
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isModeEdit, setIsModeEdit] = useState(false);
  const [isShowCreator, setIsShowCreator] = useState(false);
  const handlerCloseInput = useCallback(() => {
    setIsModeEdit(false);
    if (defaultValue !== undefined) {
      if (!defaultValue) {
        setIsShowCreator(true);
      }
      setFieldValue(name, defaultValue);
    }
    localStorage.removeItem('isFocused');
  }, [defaultValue]);

  const { wrapperRef } = useOuterElementClick({
    isShow: isModeEdit,
    closeModal: handlerCloseInput,
  });

  const handlerShowInput: ReturnType<TFUseInputAutoSave>['handlerShowInput'] =
    () => {
      setIsShowCreator(false);
      setIsModeEdit(true);
    };

  const handlerOpenModeEdit = useCallback(() => {
    setIsModeEdit(true);
  }, []);

  const watcherEnter = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.which === 9 ||
        event.which === 13 ||
        event.code === 'Enter' ||
        event.code === 'NumpadEnter'
      ) {
        if (
          defaultValue === undefined &&
          isEmptyField &&
          handlerInputEnter &&
          !isError
        ) {
          handlerInputEnter();
          inputRef.current?.blur();
          localStorage.removeItem('isFocused');
          setIsModeEdit(false);
        }
        if (defaultValue === undefined && !value) {
          handlerCloseInput();
        } else if (handlerInputEnter && !isError && !isEmptyField) {
          if (defaultValue !== value) {
            handlerInputEnter();
            inputRef.current?.blur();
            localStorage.removeItem('isFocused');
            setIsModeEdit(false);
          } else {
            handlerCloseInput();
          }
        }
      }
    },
    [handlerInputEnter, defaultValue, value, isError],
  );

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.addEventListener('keydown', watcherEnter);
    }
    return () => {
      inputRef.current?.removeEventListener('keydown', watcherEnter);
    };
  }, [value, isError]);

  useEffect(() => {
    if (defaultValue !== undefined) {
      if (!defaultValue) {
        setIsShowCreator(true);
      }
      setIsModeEdit(false);
      if (defaultValue !== value) {
        setFieldValue(name, defaultValue);
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (isModeEdit) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [isModeEdit]);

  useEffect(() => {
    if (autoFocus) {
      if (inputRef && inputRef.current) {
        setIsModeEdit(true);
        inputRef.current.focus();
      }
    }
  }, [autoFocus, data]);

  return {
    isModeEdit,
    isShowCreator,
    handlerShowInput,
    inputRef,
    wrapperRef,
    handlerOpenModeEdit,
  };
};
