import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'components/UI/Loader';
import cn from 'classnames';
import css from '../styles.module.scss';

interface IProcessProps {
  creatingState: boolean;
}

const Process: FC<IProcessProps> = ({ creatingState }) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(css.creating, {
        _isNoText: !creatingState,
      })}
    >
      <div className={css.creating_icon}>
        <Loader />
      </div>
      {creatingState && <div>{t(`ord.contracts.creating_in_progress`)}</div>}
    </div>
  );
};

export default Process;
