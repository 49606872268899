import BaseModal from 'components/UI/BaseModal';
import Button from 'components/UI/Buttons/buttons';
import LabelField from 'components/UI/LabelField';
import NewSelect, { OptionTypeBase } from 'components/UI/NewSelect';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import InputAutoSave from 'components/UI/InputAutoSave';
import { RGetPersonal } from 'domains/campaign/types';
import { Form, Field, FormikProvider, FieldProps } from 'formik';
import { selectDefault } from 'utils/selectDefault';
import { CreatableCustomSelect } from 'components/UI/NewSelect/CreatableCustomSelect';
import Load from 'components/UI/Load';
import css from './styles.module.scss';
import { useCampaignInGrid } from './hooks/useCampaignInGrid';

interface ICampaignInGridModalProps {
  setCreateCampaignInOrdModal: (value: React.SetStateAction<boolean>) => void;
  createCampaignInOrdModal: boolean;
  campaign: RGetPersonal | null;
  createOrdName: boolean;
  setIsCreateGridCampaignLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setCreateOrdName: React.Dispatch<React.SetStateAction<boolean>>;
}

const CampaignInGridModal: FC<ICampaignInGridModalProps> = ({
  setCreateCampaignInOrdModal,
  createCampaignInOrdModal,
  campaign,
  createOrdName,
  setIsCreateGridCampaignLoading,
  setCreateOrdName,
}) => {
  const { t } = useTranslation();
  const [nameCampaignInOrd, setNameCampaignInOrd] = useState<
    OptionTypeBase[] | null
  >(null);
  const [ update, setUpdate ] = useState<boolean>(false);

  const {
    formik,
    ordNameSelectOptions,
    contractsOptions,
    isGridCampaignForContractLoading,
    defaultOrdNameType,
    setDefaultOrdNameType,
    onCloseHandler,
    onCreateHandler,
    isEmpty,
  } = useCampaignInGrid(
    campaign,
    setIsCreateGridCampaignLoading,
    setCreateCampaignInOrdModal,
    setNameCampaignInOrd,
    update
  );

  useEffect(() => () => {
    setNameCampaignInOrd(null);
    setUpdate(false);
    setCreateOrdName(true);
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form>
        <BaseModal
          onClose={() => onCloseHandler()}
          isOpen={createCampaignInOrdModal}
          actions={
            <>
              <Button
                title={t(`cancel_btn`)}
                transparent
                height={40}
                buttonAttributes={{
                  onClick: () => onCloseHandler(),
                }}
              />
              <Button
                title={t(`create_btn`)}
                height={40}
                buttonAttributes={{
                  onClick: () => onCreateHandler(),
                  disabled: !isEmpty(formik.errors) ||
                  (!formik.values.ord_name || !formik.values.contract_uuid),
                }}
              />
            </>
          }
          title={t(`ord.contracts.campagn_grid`)}
        >
          <div className={css.createOrdCampaignModal}>
            <div className={css.subtitle}>
              {t(`ord.contracts.campaignInGridModalSubtitle`)}
            </div>
            <div className={css.form}>
              <LabelField
                label={`${t(`ord.contracts.one`)} *`}
                classname={cn(css.field, {
                  _isError: !!formik.errors.contract_uuid,
                })}
              >
                <Field name="contract_uuid">
                  {({ field, form }: FieldProps) => (
                    <div className={css.contract_link}>
                      <NewSelect
                        field={field}
                        form={form}
                        error={!!formik.errors.contract_uuid}
                        errorText={formik.errors.contract_uuid}
                        selectAttributes={{
                          options: contractsOptions?.filter(
                            (contract) => contract.status === 'ACTIVE',
                          ),
                          placeholder: t(`ord.contracts.placeholder_search`),
                          value:
                            contractsOptions &&
                            selectDefault(
                              String(formik.values.contract_uuid),
                              contractsOptions,
                            ),
                        }}
                        heightCustom={32}
                        onCustomChange={(val) => {
                          formik.setFieldValue('contract_uuid', val);
                          formik.setFieldValue('kktu_codes', '');
                          setUpdate(true);
                        }}
                        viewStyle="Horizontal"
                      />
                    </div>
                  )}
                </Field>
              </LabelField>
              <LabelField
                label={`${t(`campaigns_page.create_campaign.ord_name`)} *`}
                classname={cn(css.field, {
                  _isError: !!formik.errors.ord_name && !createOrdName,
                })}
              >
                <div className={cn(css.createNameOrd)}>
                  <NewSelect
                    selectAttributes={{
                      placeholder: t(`ord.customer_select`),
                      options: ordNameSelectOptions,
                      value: defaultOrdNameType,
                    }}
                    onCustomChange={(val) => {
                      const currentEl = ordNameSelectOptions.find(
                        (option) => option.value === val,
                      );
                      formik.setFieldValue('ord_name', '');
                      setDefaultOrdNameType(
                        currentEl || ordNameSelectOptions[1],
                      );
                      return val === 'create'
                        ? setCreateOrdName(true)
                        : setCreateOrdName(false);
                    }}
                    viewStyle="Horizontal"
                    heightCustom={32}
                  />
                  {!createOrdName && !isGridCampaignForContractLoading && (
                    <Field name="ord_name">
                      {({ form, field }: FieldProps) => (
                        <CreatableCustomSelect
                          field={field}
                          form={form}
                          error={!!formik.errors.ord_name}
                          errorText={formik.errors.ord_name}
                          selectAttributes={{
                            isClearable: true,
                            options: nameCampaignInOrd ?? [],
                            defaultValue: campaign?.ord_name
                              ? {
                                  label: campaign?.ord_name,
                                  value: campaign?.ord_name,
                                }
                              : null,
                            onInputChange: (val: string) =>
                              val.length <= 150 ? val : val.substring(0, 150),
                          }}
                          onCustomChange={(val) => {
                            formik.setFieldValue('kktu_codes', val.kktu_codes);
                            formik.setFieldValue('uuid', val.value);
                          }}
                          viewStyle="Horizontal"
                          heightCustom={32}
                        />
                      )}
                    </Field>
                  )}

                  {createOrdName && (
                    <InputAutoSave
                      name="ord_name"
                      label=""
                      placeholder={t(`campaigns_page.create_campaign.ord_name`)}
                      value={formik.values.ord_name}
                      setFieldValue={formik.setFieldValue}
                      isError={!!formik.errors.ord_name}
                      errorText={formik.errors.ord_name}
                    />
                  )}

                  {!createOrdName && isGridCampaignForContractLoading && (
                    <Load
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                    />
                  )}
                </div>
              </LabelField>

              <InputAutoSave
                name="kktu_codes"
                label={t(`campaigns_page.create_campaign.kktu`)}
                placeholder={t(`campaigns_page.create_campaign.kktu`)}
                value={formik.values.kktu_codes}
                setFieldValue={formik.setFieldValue}
                isError={!!formik.errors.kktu_codes}
                errorText={formik.errors.kktu_codes}
              />
            </div>
          </div>
        </BaseModal>
      </Form>
    </FormikProvider>
  );
};

export default CampaignInGridModal;
