import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/UI/Tooltip';
import { FiRefreshCcw } from 'react-icons/fi';
import css from '../styles.module.scss';

interface IActionsProps {
  onClickAction: () => void;
}

const Actions: FC<IActionsProps> = ({ onClickAction }) => {
  const { t } = useTranslation();

  return (
    <div className={css.actions}>
      <Tooltip
        title={
          <div className={css.update}>
            <FiRefreshCcw size={18} />
          </div>
        }
        onClick={onClickAction}
      >
        {t(`update_btn`)}
      </Tooltip>
    </div>
  );
};

export default Actions;
