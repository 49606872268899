import React, { FC } from 'react';
import cn from 'classnames';
import css from './styles.module.scss';
import LabelField from '../LabelField';
import Creator from './components/Creator';
import { useInputAutoSave } from './hook';
import Input from './components/Input';
import { toolTipType } from '../Input/types';

type InputAutoSaveProps = Parameters<typeof useInputAutoSave>[0] & {
  label: string;
  maxLength?: number;
  errorText?: string;
  isError?: boolean;
  isSaved?: boolean;
  isDisable?: boolean;
  placeholder?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  data?: any;
  tooltip?: toolTipType;
  isEmptyField?: boolean;
};

const InputAutoSave: FC<InputAutoSaveProps> = ({
  label,
  defaultValue,
  maxLength,
  isError = false,
  errorText = '',
  value,
  handlerInputEnter,
  name,
  setFieldValue,
  isSaved,
  autoFocus,
  isDisable,
  onBlur,
  placeholder,
  data,
  tooltip,
  isEmptyField
}) => {
  const {
    isModeEdit,
    isShowCreator,
    handlerShowInput,
    inputRef,
    wrapperRef,
    handlerOpenModeEdit,
  } = useInputAutoSave({
    defaultValue,
    value,
    handlerInputEnter,
    name,
    setFieldValue,
    isError,
    autoFocus,
    data,
    isEmptyField
  });

  return (
    <LabelField label={label} isError={isError} tooltip={tooltip}>
      <div
        className={cn(css.container, 'InputAutoSaveContainer', {
          isDisable,
          isError,
        })}
        ref={wrapperRef}
      >
        <div className={cn(css.item, { isHide: !isShowCreator })}>
          <Creator onClick={handlerShowInput} />
        </div>
        <div className={cn(css.item, { isHide: isShowCreator })}>
          <Input
            name={name}
            inputValue={value}
            setInputValue={setFieldValue}
            maxLength={maxLength}
            inputRef={inputRef}
            isModeEdit={isModeEdit}
            errorText={errorText}
            isError={isError}
            isSaved={isSaved}
            isDisable={isDisable}
            handlerOpenModeEdit={handlerOpenModeEdit}
            onBlur={onBlur}
            placeholder={placeholder}
          />
        </div>
      </div>
    </LabelField>
  );
};
export default InputAutoSave;
