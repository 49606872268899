import TooltipPoratal from 'components/UI/TooltipPoratal';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckCircleSvg } from 'assets/check-circle.svg';
import { ReactComponent as ArchiveSvg } from 'assets/archive.svg';
import { FiAlertCircle, FiXCircle } from 'react-icons/fi';
import { TCampaignGrid } from 'domains/campaign/types';
import css from '../styles.module.scss';

interface IStatusProps {
  status: TCampaignGrid['status'];
}

const Status: FC<IStatusProps> = ({ status }) => {
  const { t } = useTranslation();
  const getTooltipData = () => tooltipData[status];

  const tooltipData: {
    [str: string]: {
      hintText: string;
      icon: ReactNode;
    };
  } = {
    ACTIVE: {
      hintText: t(`ord.contracts.active`),
      icon: (
        <CheckCircleSvg
          style={{
            color: '#149C08',
            width: '20px',
            height: 'auto',
          }}
        />
      ),
    },
    ARCHIVE: {
      hintText: t(`ord.contracts.archived`),
      icon: <ArchiveSvg className={css.archive} />,
    },
    ON_APPROVAL: {
      hintText: t(`ord.contracts.in_moderation`),
      icon: <FiAlertCircle size={20} color="#FF9C08" />,
    },
    DELETED: {
      hintText: t(`campaigns_page.campaigns_status.del`),
      icon: <FiXCircle size={20} color="#FF3636"/>,
    },
  };

  return (
    <TooltipPoratal hint={getTooltipData().hintText}>
      {getTooltipData().icon}
    </TooltipPoratal>
  );
};

export default Status;
