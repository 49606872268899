/* eslint-disable import/no-cycle */
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import Tooltip from 'components/UI/Tooltip';
import { useTranslation } from 'react-i18next';
import Button from 'components/UI/Buttons/buttons';
import LabelField from 'components/UI/LabelField';
import Input from 'components/UI/Input/input';
import NewSelect from 'components/UI/NewSelect';
import { useDetectBlockScroll } from 'utils/useDetectBlockScroll';
import css from './styles.module.scss';
import { ReactComponent as CampFilterSvg } from '../../../../assets/campFilter.svg';
import { useFilter } from './hook/useFilter';
import PartnersSelect from '../PartnersSelect';

interface IfilterProps {
  fixed: boolean;
  setFileStatus: React.Dispatch<React.SetStateAction<number>>;
  setSource: React.Dispatch<React.SetStateAction<string[]>>;
  setPublisher: React.Dispatch<React.SetStateAction<string[]>>;
  setDomain: React.Dispatch<React.SetStateAction<string[]>>;
  setPartnerXxhash: React.Dispatch<React.SetStateAction<string[]>>;
  setNeedUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setCount: React.Dispatch<React.SetStateAction<number>>;
}

export interface ISelectOption {
  label: string;
  value: string;
  partner_xxhash: string;
}

const Filter: FC<IfilterProps> = ({
  fixed,
  setFileStatus,
  setSource,
  setPublisher,
  setDomain,
  setPartnerXxhash,
  setNeedUpdate,
  setCount,
}) => {
  const { t, i18n } = useTranslation();
  const filterFromStorage = localStorage.getItem('filters');
  const parsedFiltersFromStorage =
    filterFromStorage && JSON.parse(filterFromStorage);
  const searchParams = new URLSearchParams(window.location.search);
  const type = searchParams.get('type');
  const iconRef = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] = React.useState<ISelectOption[]>(
    [],
  );
  const [sourceValue, setSourceValue] = useState<string>('');
  const [publisherValue, setPublisherValue] = useState<string>('');
  const [domainValue, setDomainValue] = useState<string>('');
  const [partners, setPartners] = useState<string[]>([]);
  const [clear, setClear] = useState<boolean>(false);
  const [filterCount, setFilterCount] = useState<number>(0);
  const options = useMemo(
    () => [
      {
        label: i18n.t(`blackLists.on_in_bl`),
        value: 'on',
      },
      {
        label: i18n.t(`blackLists.off_from_bl`),
        value: 'off',
      },
    ],
    [i18n.language],
  );
  const [currStatus, setCurrStatus] = useState<string>(options[0].value);

  const clearFilters = () => {
    setSourceValue('');
    setPublisherValue('');
    setDomainValue('');
    setClear(true);
    setSelectedOption([]);
    setNeedUpdate(true);
    localStorage.removeItem('filters');
    setTimeout(() => {
      setClear(false);
    }, 500);
  };

  const returnPrevFilters = () => {
    setSourceValue(
      parsedFiltersFromStorage && parsedFiltersFromStorage.source.join(','),
    );
    setPublisherValue(
      parsedFiltersFromStorage && parsedFiltersFromStorage.publisher.join(','),
    );
    setDomainValue(
      parsedFiltersFromStorage && parsedFiltersFromStorage.domain.join(','),
    );
    setSelectedOption(
      parsedFiltersFromStorage && parsedFiltersFromStorage.partners,
    );
  };

  const {
    wrapperRef,
    isOpenPanel,
    setIsOpenPanel,
    topPosition,
    heightPanel,
    clientsList,
  } = useFilter({
    iconRef,
    fixed,
    returnPrevFilters,
  });

  const applyFilters = () => {
    setCount(0);
    const source = sourceValue ? sourceValue.split(',') : [];
    const publisher = publisherValue ? publisherValue.split(',') : [];
    const domain = domainValue ? domainValue.split(',') : [];
    setFileStatus(currStatus === 'off' ? 0 : 1);
    setSource(source);
    setPublisher(publisher);
    setDomain(domain);
    setPartnerXxhash(partners);
    setIsOpenPanel(!isOpenPanel);
    setNeedUpdate(true);
    countOfFilters();
    const objToLocalStorage = {
      source,
      publisher,
      domain,
      partners: selectedOption,
    };
    localStorage.setItem('filters', JSON.stringify(objToLocalStorage));
  };

  const countOfFilters = () => {
    let count = 0;
    if (sourceValue) {
      count += 1;
    }
    if (publisherValue) {
      count += 1;
    }
    if (domainValue) {
      count += 1;
    }
    if (partners.length > 0) {
      count += 1;
    }
    if (
      sourceValue === '' &&
      publisherValue === '' &&
      domainValue === '' &&
      partners.length === 0
    ) {
      count = 0;
    }
    setFilterCount(count);
  };

  useEffect(() => {
    const onBeforeUnload = (e: any) => {
      if (e.currentTarget.performance.navigation.type === 1) {
        localStorage.removeItem('blTotal');
        localStorage.removeItem('selectedPartner');
        localStorage.removeItem('filters');
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  const { endScrollingBlock } = useDetectBlockScroll(true);

  return (
    <div ref={wrapperRef}>
      <div
        className={cn(css.filter, {
          _isShowPanel: isOpenPanel,
          _isHighlightIcon: filterCount > 0,
        })}
        ref={iconRef}
      >
        <Tooltip
          bottom
          title={<CampFilterSvg />}
          onClick={() => setIsOpenPanel(!isOpenPanel)}
        >
          {t('filter.customize')}
        </Tooltip>
        {filterCount > 0 && <span>{filterCount}</span>}
      </div>
      <div
        className={css.filter_panel}
        style={{
          top: `${topPosition + 48}px`,
          maxHeight: `${heightPanel}px`,
        }}
      >
        <div className={css.parametrs}>
          {type === 'client' && (
            <div className={css.item}>
              <LabelField
                label={t(`users_page.settings_modal.parthner_title`)}
              />
              <PartnersSelect
                clear={clear}
                clientsList={clientsList}
                setPartners={setPartners}
                setSelectedOption={setSelectedOption}
              />
            </div>
          )}
          <div className={css.item}>
            <LabelField label={t(`blackLists.source`)} />
            <Input
              value={sourceValue}
              showValueCount
              classname={css.filterInput}
              inputAttributes={{
                placeholder: t(`offers_page.enter_name`),
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  setSourceValue(e.target.value),
              }}
            />
          </div>
          <div className={css.item}>
            <LabelField label={t(`blackLists.publisher`)} />
            <Input
              value={publisherValue}
              showValueCount
              classname={css.filterInput}
              inputAttributes={{
                placeholder: t(`offers_page.enter_name`),
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  setPublisherValue(e.target.value),
              }}
            />
          </div>
          <div className={css.item}>
            <LabelField label={t(`blackLists.domain`)} />
            <Input
              value={domainValue}
              showValueCount
              classname={css.filterInput}
              inputAttributes={{
                placeholder: t(`offers_page.enter_name`),
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  setDomainValue(e.target.value),
              }}
            />
          </div>
          <div className={css.item}>
            <LabelField label={t(`creative.Status`)} />
            <NewSelect
              selectAttributes={{
                placeholder: t(`creative.Status`),
                options,
                closeMenuOnScroll:
                  !endScrollingBlock && endScrollingBlock !== null
                    ? () => true
                    : () => false,
                defaultValue: options[0],
              }}
              onCustomChange={(val) => setCurrStatus(val)}
              className={css.select}
              viewStyle="Vertical"
            />
          </div>
        </div>
        <div className={css.actions}>
          <Button
            lightgrey
            title={t(`clean_btn`)}
            height={40}
            buttonAttributes={{
              onClick: clearFilters,
              style: {
                color: '#222222',
              },
            }}
          />
          <Button
            title={t('apply_btn')}
            height={40}
            buttonAttributes={{
              className: css.apply,
              onClick: applyFilters,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Filter;
