import { Field, FieldProps } from 'formik';
import React, { ChangeEvent, FC } from 'react';
import { TTypeHookProps } from 'domains/creative/types';
import Input from 'components/UI/Input/input';
import { additional_settings_switch_tabs } from 'domains/creative/model/shared';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { isErrorFields } from 'utils/isErrorFields';
import cn from 'classnames';
import { SelectField } from 'types/general';
import { FiAlertCircle } from 'react-icons/fi';
import { setResponseResultDefault } from 'domains/creative/reduser';
import PriceInput from 'components/UI/PriceInput';
import { checkSizes } from 'domains/creative/creatives/creativeAdd/validate';
import { animated } from 'react-spring';
import RadioGroup from 'components/UI/RadioGroup';
import { useValidateVendorLInk } from 'domains/creative/model/hooks/useValidateVendorLInk';
import { useDispatchApp } from 'redux/rootSelectors';
import { IUseTypeProp } from 'domains/creative/creatives/creativeAdd/hooks/useType';
import EridWarning from 'components/EridWarning';
import NewSelect from 'components/UI/NewSelect';
import { Trans } from 'react-i18next';
import i18n from '../../../../../../../../i18n';
import css from '../../../styles.module.scss';

type TVideovastProp = TTypeHookProps & {
  formik: IUseTypeProp['formik'];
  defaultSizeOption: (v: string) => SelectField[] | [];
  error: {
    isError: boolean;
    message: string;
    code?: number | undefined;
    mnemonic?: string | undefined;
    description?: string | undefined;
    fields?: string[] | undefined;
    creatives?:
      | {
          xxhash: string;
          title: string;
          campaign_xxhash: string;
        }[]
      | undefined;
  };
  sizeOption: SelectField[];
  setIsDisableSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
  helper: ReturnType<typeof useValidateVendorLInk>['helper'];
  ord_registration?: boolean;
};

const index: FC<TVideovastProp> = ({
  formik: { values, setFieldValue, errors, handleChange },
  defaultSizeOption,
  setIsDisableSaveButton,
  error,
  sizeOption,
  helper,
  showBlock,
  erid,
  setToggle,
  ord_registration,
}) => {
  const dispatch = useDispatchApp();

  return (
    <div className={css.form}>
      <Tabs>
        <TabList>
          <Tab>
            <span>{i18n.t('creative.vast.tabs.tab0')}</span>
          </Tab>
        </TabList>
        <TabPanel>
          <Input
            error={!!errors.data?.vast_url || isErrorFields(error, 'vast_url')}
            errortext={
              errors.data?.vast_url ||
              (isErrorFields(error, 'vast_url')
                ? i18n.t('errors.err40_link')
                : '')
            }
            label={i18n.t('creative.vast.fields.video_url')}
            inputAttributes={{
              name: 'data.vast_url',
              value: values.data.vast_url,
              onChange: (event: ChangeEvent<HTMLInputElement>) => {
                dispatch(setResponseResultDefault());
                setFieldValue('data.vast_url', event.currentTarget.value);
              },
            }}
          />
        </TabPanel>
      </Tabs>

      <Tabs>
        <TabList>
          <Tab
            className={cn('react-tabs__tab', {
              hasError:
                !!errors?.data?.skip_duration || !!errors?.data?.duration,
            })}
          >
            <span>{i18n.t('creative.vast.tabs.tab1')}</span>
            {(!!errors?.data?.skip_duration || !!errors?.data?.duration) && (
              <FiAlertCircle size={16} className="alert-icon" />
            )}
          </Tab>
          <Tab
            className={cn('react-tabs__tab', {
              hasError: !!errors.data?.audit,
            })}
          >
            <span>
              <span>{i18n.t('creative.vast.tabs.tab2')}</span>
            </span>
            {!!errors?.data?.audit && (
              <FiAlertCircle size={16} className="alert-icon" />
            )}
          </Tab>
        </TabList>
        <TabPanel>
          <div className={css.form}>
            {ord_registration && (
              <Input
                label={i18n.t(`creative.general_description_label`)}
                isTextarea
                tooltip={{
                  text: (
                    <Trans
                      i18nKey="creative.general_description_hint"
                      components={{ br: <br /> }}
                    />
                  ),
                  colorIcon: '#222222',
                  sizeIcon: 20,
                }}
                showValueCount
                error={!!errors.data?.description}
                errortext={errors.data?.description}
                inputAttributes={{
                  name: 'data.description',
                  placeholder: i18n.t(`creative.general_description_place`),
                  maxLength: 1024,
                  value: values.data.description,
                  onChange: handleChange,
                }}
              />
            )}
            <div className={css.rough}>
              <div className={css.rough_col}>
                <PriceInput
                  error={!!errors.data?.duration}
                  errortext={errors.data?.duration}
                  label={i18n.t('creative.vast.fields.video_duration')}
                  decimalScale={0}
                  onValueChange={({ floatValue }) => {
                    setFieldValue('data.duration', floatValue);
                  }}
                  value={values.data.duration}
                  hasZero={false}
                />
              </div>
              <div className={css.rough_col}>
                <PriceInput
                  error={!!errors.data?.skip_duration}
                  errortext={errors.data?.skip_duration}
                  label={i18n.t('creative.vast.fields.video_duration_pass')}
                  decimalScale={0}
                  onValueChange={({ floatValue }) => {
                    setFieldValue('data.skip_duration', floatValue);
                  }}
                  value={values.data.skip_duration}
                  hasZero={false}
                />
              </div>
            </div>
            <Field name="size">
              {({ form, field }: FieldProps) => (
                <NewSelect
                  label={i18n.t('creative.create_creative.Size_px')}
                  form={form}
                  field={field}
                  selectAttributes={{
                    name: 'size',
                    placeholder: i18n.t(
                      'creative.create_creative.Please_select',
                    ),
                    options: sizeOption,
                    value: defaultSizeOption(`${values.size}`),
                    onChange: () => {
                      setToggle(checkSizes(values.size));
                      setFieldValue('size', values.size);
                    },
                  }}
                  onCustomChange={() => {
                    setFieldValue('width', 1)
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      .then(() => form.setFieldValue('height', 1))
                      .then(() => form.validateForm());
                  }}
                  viewStyle="Vertical"
                />
              )}
            </Field>
            <animated.div style={showBlock} className={css.sizes}>
              <PriceInput
                hasZero
                classname={css.sizes_col}
                decimalScale={0}
                label={`${i18n.t('creative.create_creative.Width')}, PX`}
                placeholder={i18n.t('creative.create_creative.Width')}
                name="width"
                error={errors.width}
                errortext={errors.width}
                value={+values.width}
                onValueChange={({ floatValue }) => {
                  if (!floatValue) {
                    setFieldValue('width', 1);
                    return;
                  }
                  setFieldValue('width', floatValue);
                }}
              />
              <PriceInput
                hasZero
                classname={css.sizes_col}
                decimalScale={0}
                label={`${i18n.t('creative.create_creative.Height')}, PX`}
                placeholder={i18n.t('creative.create_creative.Height')}
                name="height"
                error={errors.height}
                errortext={errors.height}
                value={+values.height}
                onValueChange={({ floatValue }) => {
                  if (!floatValue) {
                    setFieldValue('height', 1);
                    return;
                  }
                  setFieldValue('height', floatValue);
                }}
              />
            </animated.div>
            {erid && (
              <>
                <Input
                  label={erid.title}
                  showValueCount
                  error={!!errors.erid}
                  errortext={errors.erid}
                  inputAttributes={{
                    name: 'erid',
                    maxLength: 80,
                    value: values.erid,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('erid', e.target.value.trim()),
                  }}
                />
                {values.erid && (
                  <EridWarning
                    withAccept
                    isChangeErid
                    setIsDisableSaveButton={setIsDisableSaveButton}
                  />
                )}
              </>
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <div className={css.form}>
            <RadioGroup
              name="additional_settings_switch"
              label=""
              className={css.switch}
              options={[
                {
                  title: i18n.t('creative.video.switch.all'),
                  value: 'ALL',
                },
                {
                  title: i18n.t('creative.video.switch.quartiles'),
                  value: 'QUARTILES',
                },
                {
                  title: i18n.t('creative.video.switch.show'),
                  value: 'SHOW',
                },
              ]}
              setFieldValue={setFieldValue}
              checked={values.additional_settings_switch}
            />
            {values.additional_settings_switch !== 'ALL' && (
              <>
                {Object.keys(values.data.audit).map(
                  (value) =>
                    additional_settings_switch_tabs[
                      values.additional_settings_switch
                    ].includes(value) && (
                      <Input
                        key={`data.audit.${value}`}
                        error={
                          errors.data?.audit && !!errors.data?.audit[value]
                        }
                        errortext={
                          errors.data?.audit && errors.data?.audit[value]
                        }
                        helper={helper[value]}
                        label={i18n.t(`creative.video.fields.video_${value}`)}
                        inputAttributes={{
                          name: `data.audit.${value}`,
                          placeholder: '',
                          style: { height: 48 },
                          value: values.data.audit[value],
                          onChange: handleChange,
                        }}
                      />
                    ),
                )}
              </>
            )}
            {values.additional_settings_switch === 'ALL' && (
              <>
                {Object.keys(values.data.audit).map((value) => (
                  <Input
                    key={`data.audit.${value}`}
                    error={errors.data?.audit && !!errors.data?.audit[value]}
                    errortext={errors.data?.audit && errors.data?.audit[value]}
                    helper={helper[value]}
                    label={i18n.t(`creative.video.fields.video_${value}`)}
                    inputAttributes={{
                      name: `data.audit.${value}`,
                      placeholder: '',
                      style: { height: 48 },
                      value: values.data.audit[value],
                      onChange: handleChange,
                    }}
                  />
                ))}
              </>
            )}
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default index;
