import cn from 'classnames';
import { Prompt } from 'react-router-dom';
import { FiChevronDown, FiX, FiSettings, FiRefreshCcw } from 'react-icons/fi';
import { animated } from 'react-spring';
import React, {
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useCampaignInfo } from 'domains/campaign/model/selectors';
import Help from 'components/Help';
import { checkedLocalization } from 'utils/checkedLocalization';
import { useUserTheme } from 'domains/user/model/selectors';
import Tooltip from 'components/UI/Tooltip';
import { ReactSVG } from 'react-svg';
import TargetItemAlert from 'components/TargetItemAlert';
import { useSelector } from 'react-redux';
import { useIsGroup } from 'domains/user/hooks';
import css from './styles.module.scss';
import { icons } from '../model/shared/icons';
import ToggleSwitch from '../../../components/UI/ToggleSwitch';
import Filter from '../../../components/Target/Item/Filter';
import ExceptionFilter from '../../../components/Target/Item/ExceptionFilter';
import Search from '../../../components/Target/Item/Search';
import DateRangeFilter from '../../../components/DateRangeFilter';
import ListOptions from '../listOptions/index';
import { parsingDate } from '../../../utils/momentParse';
import { useDataGrid } from './useDataGrid';
import { Buttons } from './Buttons';
import { BodyItem } from './BodyItem';
import CheckBoxM from '../modals/CheckBoxM';
import ContextBoxM from '../modals/ContextBoxM';
import AddTextareaModal from '../modals/AddTextareaModal';
import ContextModal from '../../../components/ContextModal';
import HierarchicalActions from './HierarchicalActions';
import { useGetReport } from '../model/hooks/useGetReport';
import { TargetKey, Tdata, TDownloadStata, TLeftD } from '../model/types';
import { useDataTree } from './HierarchicalActions/hooks/useDataTree';
import { resetTargetType } from '../model/actions';
import { useDispatchApp } from '../../../redux/rootSelectors';
import { TDates } from '../../../types/general';
import { arTargetTree, contextTarget } from '../model/shared';
import { delAllRequest, resetGeoSearchData } from '../reduser';
import AddLimitsModal from '../modals/AddLimitsModal';
import { ILimits } from '../types';
import plagSvg from '../../../components/Target/Item/icons/plag.svg';
import { useInheritSettingsHint } from '../model/hooks/useInheritSettingsHint';
import { recursionSearchElementById } from '../model/utils/recursionChecked';
import { useRecursiveGridEventHandlers } from '../model/hooks/useRecursiveGridEventHandlers';
import { usePageContextMData } from '../model/hooks/usePageContextMData';
import { useOnSaveTargetHandler } from '../model/hooks/useOnSaveTargetHandler';
import { useCreateColumns } from '../model/hooks/useCreateColumns';
import { clearAllTargetItems, toggleInheritSettings } from '../actions';
import { selectorTargetUserSettings } from '../model/selectors';

const GetReportWorker = new Worker('/workers/getData4TSV.js?v=1.0');

type Props = {
  /** название таргета */
  title: string;
  /** тип таргета */
  type: TargetKey;
  /** Внешний вид таргета в зависимости от id */
  view: number;
  /** ID Креатива/кампании */
  id: string;
  /** Айди Клиента */
  campaign_xxhash: string;
  /** В креативе */
  isCreative: boolean;
  /** В компании */
  isCompain: boolean;
  /** Период */
  period: TDates['period'];
  initCommonTypePeriod: TDates['type'];
  /** Доступны ли в таргете режим инвертирования и значение n/a */
  is_wo_available: boolean;
  /** Является ли справочник динамическим (пример: таргеты URL и Domain ) */
  is_dynamic_catalog: boolean;
  /** Показать/скрыть статистику */
  isShowStatistic: boolean;
  /** Особая логика для некоторых таргетов с деревом от каталога */
  isSourceLikeLogic: boolean;
};
const Item: FC<Props> = ({
  title,
  type,
  view,
  id,
  campaign_xxhash,
  period,
  initCommonTypePeriod,
  is_wo_available,
  isCreative,
  isCompain,
  isShowStatistic,
  isSourceLikeLogic,
}) => {
  const { isLocked } = useIsGroup();
  const { t } = useTranslation();
  const dispatch = useDispatchApp();

  const [isLimitsView, setIsLimitsView] = useState(false);
  const [showStatistic, setShowStatistic] = useState(() => isShowStatistic);

  const { data: dataCampaign } = useCampaignInfo();
  const [commonTypePeriod, setCommonTypePeriod] = useState<TDates['type']>(
    () => initCommonTypePeriod,
  );
  const [range, setRange] = useState<TDates['period']>(() => period);

  useEffect(() => {
    setCommonTypePeriod(initCommonTypePeriod);
  }, [initCommonTypePeriod]);

  useEffect(() => {
    setRange(period);
  }, [period]);

  const rangeProps = useMemo(
    () => ({
      range,
      initialTitleDay: commonTypePeriod,
      dateStart: parsingDate(
        (dataCampaign && dataCampaign.date_start.date) ||
          moment().subtract(365, 'days'),
      ).toDate(),
      dateEnd: parsingDate(
        (dataCampaign && dataCampaign.date_end.date) || new Date(),
      ).toDate(),
    }),
    [range, commonTypePeriod],
  );

  const [toggle, setToggle] = useState<boolean>(false);
  const [onlyChecked, setOnlyChecked] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [isShowDownloadButton, setIsShowDownloadButton] = useState(false);
  const targetUserSettings = useSelector(selectorTargetUserSettings);
  const targetUserSettingsData = targetUserSettings.get('data');

  const isSourceLike = type === 'source' || type === 'network';

  const [searchRefresher, setSearchRefresher] = useState(false);

  const searchRefresh = () => {
    if (onlyChecked) {
      setSearchRefresher((prev) => !prev);
    }
  };


  const {
    settings,
    total,
    data,
    leftD,
    isLoading,
    isEmpty,
    active,
    use_inherit_settings,
    setMiddeleData,
    isInvertMode,
    setIsInvertMode,
    setIsLoading,
    statisticsIsGroup,
    items: itemsServer,
    userSettingsLTU,
    creativeLTU,
    isError,
    fetchStatistics,
    refreshTargetDataHandler,
    updateCatalogHandler,
    isInvertModeServe,
    search,
    setSearch,
    isValidatedTarget,
    errorValidateText,
    setErrorValidateText,
    error,
    setLeftD,
    setData,
    statisticTarget,
    deletedItemsArr,
    deleteAllTargetItems,
    clearDeletedArr,
    setisLoadingClearTarget,
    setTouchedItemsToSave,
    touchedItemsToSave,
  } = useDataGrid({
    type,
    xxhash: id,
    campaign_xxhash,
    period: range,
    toggle,
    isCancel,
    isCreative,
    view,
    isShowStatistics: showStatistic,
    isSourceLikeLogic,
    showSaveButton,
    searchRefresh,
    setShowSaveButton,
    onlyChecked,
  });

  const {
    popular,
    tree,
    handleToggle,
    verifyCheckbox,
    handleChangeCheckbox,
    handleClearAll,
    updateTree,
    searchGeo,
    setSearchGeo,
    setGeoSearchCursor,
    geoSearchCursor,
    geoRes,
    targetGeoColumnLeft,
    targetGeoColumnData,
    popularGeoID,
    verifyPartiallyChecked,
    isErrorSettings,
    itemsOnSave,
    searchAll,
    onChangeLimitsTree,
    removeLimitsFromTree,
    checkAllInArr,
    isLoadingClearTargetTree,
    setisLoadingClearTargetTree,
    updateItem,
  } = useDataTree({
    onlyChecked,
    xxhash: id,
    toggle,
    isCreative,
    deletedItemsArr,
    type,
    setIsLoading,
    setShowSaveButton,
    showSaveButton,
    statisticTarget,
    searchRefresh,
    searchRefresher,
  });

  const generalIsLoading = isLoading || isLoadingClearTargetTree;

  const {
    onChekedLD,
    onChangeCellData,
    onChangeValHandler,
    onChangeLimits,
    onRemoveLimits,
    onRemoveItem,
    onChekedLD4arr,
    onChangeTitleVal,
    checkItemLimits,
  } = useRecursiveGridEventHandlers({
    leftD,
    setLeftD,
    data,
    isSourceLike: isSourceLikeLogic,
    deletedItemsArr,
    setData,
    setShowSaveButton,
  });

  const {
    handlerCloseIsPageContextM,
    isPageContextM,
    pageContextValue,
    showPageContextRow,
    showPageAppRow,
    resetTargetError,
  } = usePageContextMData({ type, settings, leftD });

  const [use_inherit_settingsState, SetUse_inherit_settingsState] =
    useState<boolean>(() => !use_inherit_settings);

  const { columns, keysFields } = useCreateColumns({
    isLoading: generalIsLoading,
    isSourceLike,
    isUnlockBidEdit: isSourceLikeLogic,
    settings,
    type,
    isEmpty,
    use_inherit_settingsState,
    isLimitsView,
    statisticsIsGroup,
    onChangeValHandler,
    updateItem,
    isLocked,
  });

  // показать кнопку загрузки отчета когда прогрузилась стата
  useEffect(() => {
    const statDataObj = statisticTarget?.get('data');
    const isStat = Boolean(statDataObj && !Array.isArray(statDataObj));
    setIsShowDownloadButton(isStat);
  }, [statisticTarget]);

  const { isThemeFull } = useUserTheme();

  const downloadState = useRef<TDownloadStata>({
    left: [],
    right: [],
  });

  useEffect(() => {
    if (showStatistic !== isShowStatistic) {
      setShowStatistic(() => isShowStatistic);
      if (isShowStatistic && toggle) {
        setIsLoading(() => true);
      }
    }
  }, [isShowStatistic]);

  useEffect(() => {
    SetUse_inherit_settingsState(() => !use_inherit_settings);
    return function () {
      dispatch(delAllRequest());
    };
  }, [userSettingsLTU, creativeLTU]);

  const { onSave } = useOnSaveTargetHandler({
    id,
    type,
    tree,
    title,
    leftD,
    itemsOnSave,
    settings,
    isCreative,
    itemsServer,
    isSourceLike,
    isSourceLikeLogic,
    isInvertMode,
    deletedItemsArr,
    statisticsIsGroup,
    use_inherit_settings,
    use_inherit_settingsState,
    setIsLoading,
    clearDeletedArr,
    resetTargetError,
    setShowSaveButton,
    touchedItemsToSave,
    setTouchedItemsToSave,
    targetUserSettingsData,
  });

  const { getValueFromSettingForKey } = useUserTheme();

  // --> Связанная по смыслу логика, можно вынести в отдельный хук фильтрации всех включенных
  const recursionPushCheck = (item: TLeftD, resultArr: boolean[]) => {
    if (item.children && item.children.length) {
      item.children.forEach((child) => recursionPushCheck(child, resultArr));
      return;
    }
    resultArr.push(item.is_checked);
  };

  // Проверяет значение родительского чекбокса для фильтрации по типу "показать все включенные",
  // если хоть один дочерний true, то родительский тоже true
  const checkCheckbox = (item: TLeftD) => {
    const resultArr: boolean[] = [];
    if (item.children) {
      item.children.forEach((child) => recursionPushCheck(child, resultArr));
    } else {
      resultArr.push(item.is_checked);
    }
    return resultArr.some((itemCheck) => itemCheck === true);
  };
  // фильтр по типу "показать все включенные"
  const customFilter = useCallback(
    (lD: TLeftD[], d: Tdata[]) => {
      const mapped: { index: number; value: boolean }[] = lD.map((el, i) => ({
        index: i,
        value:
          el.children?.length && !statisticsIsGroup
            ? checkCheckbox(el)
            : el.is_checked,
      }));
      const mappedF = mapped.filter((item) => item.value);
      let lDCF: TLeftD[] = [];
      let dCF: Tdata[] = [];
      mappedF.forEach((item) => {
        lDCF.push(lD[item.index]);
        dCF.push(d[item.index]);
      });
      if (lDCF.some((ld) => ld.children)) {
        dCF = dCF.map((rd, index) => {
          if (lDCF[index]?.children && rd?.children) {
            const { dCF: recdCF } = customFilter(
              lDCF[index].children || [],
              rd.children,
            );
            return { ...rd, children: recdCF };
          }
          return rd;
        });
        lDCF = lDCF.map((ld, index) => {
          if (ld?.children) {
            const { lDCF: recLD } = customFilter(
              ld.children,
              dCF[index]?.children || [],
            );
            return { ...ld, children: recLD };
          }
          return ld;
        });
      }
      return { lDCF, dCF };
    },
    [statisticsIsGroup],
  );
  // <-- Связанная по смыслу логика, можно вынести в отдельный хук фильтрации всех включенных

  const onCancel = useCallback(() => {
    setIsInvertMode(!!isInvertModeServe);
    clearDeletedArr();
    SetUse_inherit_settingsState(() => !use_inherit_settings);
    setShowSaveButton(false);
    setIsCancel((val) => !val);
    resetTargetError();
    closeHintEvent();
  }, [isInvertModeServe, use_inherit_settings, onlyChecked]);

  useEffect(() => {
    if (showSaveButton) {
      onCancel();
    }
  }, [range, showStatistic]);

  useEffect(() => {
    if (toggle) {
      setIsLoading(() => true);
    }
  }, [range]);

  const [openCheckBoxM, setCheckBoxM] = useState(false);
  const [openContextModal, setOpenContextModal] = useState(false);
  const [openContextBoxM, setContextBoxM] = useState(false);
  const [titleContextBoxM, setTitleContextBoxM] = useState<string>('');
  const [openLimitsModal, setLimitsModal] = useState(false);
  const [itemData, setItemData] = useState({ key: '', title: '' });
  const [dataContextBoxM, setDataContextBoxM] = useState<
    { title: string; value: string } | undefined
  >(undefined);
  const [openAddAjaxListM, setAddAjaxListM] = useState(false);

  useEffect(() => {
    dispatch(resetGeoSearchData());
  }, [openAddAjaxListM]);

  const currentLimitsData = (itemKey: string): ILimits | undefined => {
    if (type === 'geo_id') {
      return recursionSearchElementById(+itemKey, tree)?.limits;
    }
    let currentItem: ILimits | undefined;
    leftD.forEach((item) => {
      if (!currentItem) {
        currentItem = item.key === itemKey ? item.limits : undefined;
        if (item.children?.length) {
          currentItem = item.children.find(
            (child) => child.key === itemKey,
          )?.limits;
        }
      }
    });
    return currentItem;
  };

  //--------------------------------------------
  const onOpenModal = useCallback(
    (isListMessage = false) => {
      setTitleContextBoxM(t(`targetings.add_popap_tit.${type}`));
      setDataContextBoxM(undefined);

      if (contextTarget.includes(type)) {
        return setContextBoxM(true);
      }

      if (settings?.is_dynamic_catalog || isListMessage) {
        return setAddAjaxListM(true);
      }

      return setCheckBoxM(true);
    },
    [settings],
  );

  const onCloseCheckBoxM = useCallback(() => {
    setCheckBoxM(false);
  }, []);

  const onCloseLimitsModal = useCallback(() => {
    setLimitsModal(false);
  }, []);

  const limitsClickHandler = useCallback(
    (currentKey: string | number, currentTitle: string) => {
      setLimitsModal(true);
      setItemData({ key: String(currentKey), title: currentTitle });
    },
    [openLimitsModal],
  );
  const onCloseContextBoxM = useCallback(() => {
    setContextBoxM(false);
  }, []);
  const onCloseAddAjaxListM = useCallback(() => {
    setAddAjaxListM(false);
  }, []);

  const onCloseContextModal = useCallback(() => {
    setOpenContextModal(false);
  }, []);

  const onChangeContextBox = (row: TLeftD) => () => {
    setTitleContextBoxM(t('targetings.keywords.t8'));
    setDataContextBoxM({ title: row.title, value: row.value ?? '' });
    setContextBoxM(true);
  };
  const onShowContextBox = (row: TLeftD) => () => {
    setDataContextBoxM({ title: row.title, value: row.value ?? '' });
    setOpenContextModal(true);
  };
  //--------------------------------------------

  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const onToggleSearch = (val: boolean) => {
    setOpenSearch(val);
  };

  // !! вот тут какойто мусор я сним разобратся не успел
  const [activeCard, setActiveCard] = useState<boolean>(false);
  const [cardActivator, setCardActivator] = useState<
    MutableRefObject<HTMLDivElement | null> | null | undefined
  >(null);

  const onToggle = useCallback(() => {
    setToggle(!toggle);
    if (!showSaveButton && !toggle) {
      setIsLoading(true);
    }
  }, [toggle, showSaveButton]);

  const className = '';

  const showBlock = undefined;

  const toggleShowStatHandler = () => {
    if (showStatistic) {
      dispatch(resetTargetType(type));
    } else {
      setIsLoading(() => true);
    }
    setShowStatistic((prev) => !prev);
  };

  const onSetActive = (
    val: boolean,
    ref: MutableRefObject<HTMLDivElement | null> | undefined,
  ) => {
    if (val) {
      setActiveCard(() => val);
      setCardActivator(() => ref);
    } else if (ref === cardActivator) {
      setActiveCard(() => val);
      setCardActivator(() => ref);
    }
  };

  const checkPrompt = () =>
    t(`targetings.prompt.items.${type}`) !== `targetings.prompt.items.${type}`;

  //-------------------------------------------------------------------
  const { disGetReport, onGetReport } = useGetReport({
    title,
    total,
    downloadState,
    worker: GetReportWorker,
    keysFields,
  });

  const { showHint, closeHintEvent, showHintEvent } = useInheritSettingsHint({
    use_inherit_settingsState,
  });

  const handleCloseAlertItem = () => {
    if (errorValidateText) {
      setErrorValidateText('');
      return;
    }
    resetTargetError();
  };

  return (
    <div id={`target-table-${type}`}>
      <div
        className={cn(css.card, className, {
          isToggle: toggle,
          isEditing: true,
          hasValues: active,
          isCreative,
          isActive: activeCard,
        })}
      >
        <div
          className={cn(css.header, {
            isThemeFull,
          })}
        >
          <button onClick={onToggle} type="button">
            {' '}
          </button>
          <div className={css.title}>
            <div
              className={cn(css.img, {
                isSettingCreative: isCreative && use_inherit_settingsState,
              })}
            >
              {icons.get(type) ? icons.get(type) : <ReactSVG src={plagSvg} />}
            </div>
            <div className={css.text}>
              <div className={css.name}>
                <strong>{title}</strong>
                <FiChevronDown
                  size={20}
                  color="#0a0a0a"
                  className={css.arrow}
                />
                {checkPrompt() && getValueFromSettingForKey('HideHelp') && (
                  <Help
                    title={title}
                    open={!toggle}
                    helpattributes={{
                      text: checkedLocalization(
                        `targetings.prompt.items.${type}.text`,
                        '',
                        false,
                      ),
                      link: checkedLocalization(
                        `targetings.prompt.items.${type}.link`,
                        '',
                        false,
                      ),
                    }}
                  />
                )}
              </div>

              {isCreative && view !== 1 && (
                <div className={css.hint}>
                  <span
                    style={{
                      color: use_inherit_settingsState ? '#149C08' : '',
                    }}
                  >
                    {`
                ${t('targetings.use_set')}
                ${
                  !use_inherit_settingsState
                    ? t('targetings.use_set1')
                    : t('targetings.use_set2')
                }
              `}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div
            className={cn(css.helpers, {
              readOnly: view === 1,
            })}
          >
            {view !== 1 && (
              <Buttons
                showSaveButton={showSaveButton}
                onCancel={onCancel}
                onSave={onSave}
                isDisabled={generalIsLoading}
                read_only={view === 1}
                isValidatedTarget={isValidatedTarget}
              />
            )}
          </div>
        </div>
        <animated.div style={showBlock} className={css.content}>
          {isCreative && (
            <div
              className={cn(css.settingsToggle, {
                _isShowHint: showHint && !isLocked,
              })}
            >
              <ToggleSwitch
                activateText={t('targetings.use_set_creo')}
                deactivateText={t('targetings.use_set_creo')}
                checked={use_inherit_settingsState}
                disabled={view === 1 || isLocked}
                onChange={() => {
                  if (use_inherit_settingsState) {
                    dispatch(
                      clearAllTargetItems({
                        xxhash: id,
                        target_key: type,
                        setisLoadingClearTarget: arTargetTree.includes(type)
                          ? setisLoadingClearTargetTree
                          : setisLoadingClearTarget,
                        hideAlert: true,
                      }),
                    );
                  } else {
                    dispatch(
                      toggleInheritSettings({
                        xxhash: id,
                        target_key: type,
                        setIsLoading,
                        targeting_data: {
                          is_invert_mode: isInvertMode,
                          items: {},
                        },
                      }),
                    );
                  }
                  SetUse_inherit_settingsState(
                    () => !use_inherit_settingsState,
                  );
                  if (!use_inherit_settingsState) {
                    closeHintEvent();
                  }
                }}
              />
              <div className={css.settingsToggleHelp}>
                <FiSettings
                  size={20}
                  color="#828282"
                  className={css.settingsIcon}
                />
                <div className={css.title}>
                  {t('targetings.have_camp_set_tit')}
                </div>
                <div className={css.body}>{t('targetings.have_camp_set')}</div>
                <FiX size={20} className={css.close} onClick={closeHintEvent} />
              </div>
            </div>
          )}
          {!arTargetTree.includes(type) && (
            <div>
              <div className={cn(css.actions, { isSearchOpened: openSearch })}>
                {view !== 1 && (
                  <ExceptionFilter
                    type={type}
                    view={view}
                    className={cn(css.filter)}
                    selected={isInvertMode}
                    disabled={!use_inherit_settingsState || isLocked}
                    onChange={(val: boolean) => {
                      setIsInvertMode(val);
                      setShowSaveButton(true);
                    }}
                    is_wo_available={is_wo_available}
                    onSetActive={onSetActive}
                    showHintEvent={showHintEvent}
                  />
                )}
                <div className={css.spacer} />
                {view !== 1 && (
                  <Filter
                    className={cn(css.filter)}
                    params={[t('targetings.all'), t('targetings.custom')]}
                    setOnlyChecked={setOnlyChecked}
                    onlyChecked={onlyChecked}
                  />
                )}
                {[1, 2, 5].includes(view) && !openSearch && (
                  <ToggleSwitch
                    className={css.toggleSwitch}
                    onChange={toggleShowStatHandler}
                    checked={showStatistic}
                    activateText={t(`targetings.show_statictics`)}
                    deactivateText={t(`targetings.show_statictics`)}
                  />
                )}
                <Search
                  className={cn(css.search)}
                  placeholder={`${t('targetings.search')}...`}
                  value={search}
                  setSearch={setSearch}
                  toggleSearch={(val) => onToggleSearch(val)}
                />
                <div
                  className={cn(css.update, {
                    _isDisabled: showSaveButton || generalIsLoading,
                  })}
                >
                  <Tooltip
                    title={
                      <FiRefreshCcw
                        size={20}
                        onClick={refreshTargetDataHandler}
                      />
                    }
                  >
                    {t(`targetings.update_stat`)}
                  </Tooltip>
                </div>
                {type !== 'keywords' && (
                  <DateRangeFilter
                    className={css['date-range-filter']}
                    {...rangeProps}
                    onSetDateRange={(dates) => {
                      setRange(dates);
                    }}
                    onSetActive={onSetActive}
                    isTarget
                    disabled={generalIsLoading}
                    initialTitleDay={initCommonTypePeriod}
                  />
                )}
                <div className={css.options_container}>
                  {(isShowDownloadButton || (!isSourceLike && view !== 1)) &&
                    settings && (
                      <ListOptions
                        clearAll={deleteAllTargetItems}
                        setIsInvertMode={setIsInvertMode}
                        setShowSaveButton={setShowSaveButton}
                        isCreative={isCreative}
                        isCompain={isCompain}
                        setUseSettings={SetUse_inherit_settingsState}
                        useSettings={use_inherit_settingsState}
                        className={css.options}
                        exceptions={!isSourceLike && view !== 1}
                        disabled={generalIsLoading || isLocked}
                        type={type}
                        isUpdateTarget={settings.updates}
                        view={view}
                        updateCatalogHandler={updateCatalogHandler}
                        setMiddeleData={setMiddeleData}
                        openModal={onOpenModal}
                        showHintEvent={showHintEvent}
                        onGetReport={onGetReport}
                        disGetReport={disGetReport}
                        showDownload={isShowDownloadButton}
                      />
                    )}
                </div>
              </div>
            </div>
          )}

          <div>
            {arTargetTree.includes(type) ? (
              <HierarchicalActions
                searchAll={searchAll}
                popularGeoID={popularGeoID}
                targetGeoColumnLeft={targetGeoColumnLeft}
                targetGeoColumnData={targetGeoColumnData}
                use_inherit_settingsState={use_inherit_settingsState}
                setUseSettings={SetUse_inherit_settingsState}
                geoRes={geoRes}
                geoSearchCursor={geoSearchCursor}
                setGeoSearchCursor={setGeoSearchCursor}
                searchGeo={searchGeo}
                setSearchGeo={setSearchGeo}
                isLoading={generalIsLoading}
                handleChangeCheckbox={handleChangeCheckbox}
                verifyCheckbox={verifyCheckbox}
                verifyPartiallyChecked={verifyPartiallyChecked}
                handleToggle={handleToggle}
                popular={popular}
                tree={tree}
                handleClearAll={handleClearAll}
                updateTree={updateTree}
                period={period}
                initCommonTypePeriod={initCommonTypePeriod}
                columns={columns}
                total={total}
                fetchStatistics={fetchStatistics}
                isEmpty={isEmpty}
                type={type}
                isCreative={isCreative}
                isCompain={isCompain}
                isErrorSettings={isErrorSettings}
                showHintEvent={showHintEvent}
                showStatistic={showStatistic}
                toggleShowStatHandler={toggleShowStatHandler}
                range={range}
                setRange={setRange}
                showLimits={() => setIsLimitsView((prev) => !prev)}
                isLimitsView={isLimitsView}
                checkItemLimits={checkItemLimits}
                limitsClickHandler={limitsClickHandler}
                refreshTargetDataHandler={refreshTargetDataHandler}
                setIsInvertMode={setIsInvertMode}
                setShowSaveButton={setShowSaveButton}
                exceptions={!isSourceLike && view !== 1}
                view={view}
                openModal={onOpenModal}
                onGetReport={onGetReport}
                disGetReport={disGetReport}
                showDownload={isShowDownloadButton}
                onlyChecked={onlyChecked}
                setOnlyChecked={setOnlyChecked}
                updateItem={updateItem}
              />
            ) : (
              <BodyItem
                showPageAppRow={showPageAppRow}
                showPageContextRow={showPageContextRow}
                downloadState={downloadState}
                statisticsIsGroup={statisticsIsGroup}
                onShowContextBox={onShowContextBox}
                onChangeContextBox={onChangeContextBox}
                isLoading={generalIsLoading}
                leftD={leftD}
                use_settings={use_inherit_settingsState}
                isSourceLikeLogic={isSourceLikeLogic}
                type={type}
                view={view}
                columns={columns}
                total={total}
                data={data}
                search={search}
                customFilter={customFilter}
                isEmpty={isEmpty}
                onlyChecked={onlyChecked}
                onChekedLD={onChekedLD}
                onRemoveItem={onRemoveItem}
                openModal={onOpenModal}
                read_only={view === 1}
                showStatistic={showStatistic}
                isError={isError}
                fetchStatistics={fetchStatistics}
                setLeftD={setLeftD}
                checkItemLimits={checkItemLimits}
                setShowSaveButton={setShowSaveButton}
                showHintEvent={showHintEvent}
                onChangeCellData={onChangeCellData}
                setTouchedItemsToSave={setTouchedItemsToSave}
                isLocked={isLocked}
              />
            )}
          </div>
        </animated.div>

        {openCheckBoxM && (
          <CheckBoxM
            isOpen={openCheckBoxM}
            isUpdateTarget={settings?.updates}
            onCloseModal={onCloseCheckBoxM}
            type={type}
            campaign_xxhash={campaign_xxhash}
            catalog={settings?.catalog ?? []}
            isFree={type === 'page_context'}
            onHandleSubmit={onChekedLD4arr}
          />
        )}
        {openLimitsModal && (
          <AddLimitsModal
            isOpen={openLimitsModal}
            onCloseModal={onCloseLimitsModal}
            currentItemData={itemData}
            limits={currentLimitsData(itemData.key)}
            onChangeLimits={
              type === 'geo_id' ? onChangeLimitsTree : onChangeLimits
            }
            onRemoveLimits={
              type === 'geo_id' ? removeLimitsFromTree : onRemoveLimits
            }
            checkItemLimits={checkItemLimits}
          />
        )}
        {contextTarget.includes(type) && (
          <>
            <ContextBoxM
              title={titleContextBoxM}
              type={type}
              onHandleSubmit={onChekedLD4arr}
              isOpen={openContextBoxM}
              onCloseModal={onCloseContextBoxM}
              data={dataContextBoxM}
              onChangeTitleVal={onChangeTitleVal}
              onRemoveItem={onRemoveItem}
            />
            <ContextModal
              item={{
                title: dataContextBoxM?.title ?? '',
                items: dataContextBoxM?.value ?? '',
              }}
              onClose={onCloseContextModal}
              isOpen={openContextModal}
              alertText={t('targetings.list_copy')}
            />
          </>
        )}
        {(type === 'page_context' || type === 'app') && (
          <ContextModal
            item={{
              title: pageContextValue?.title ?? '',
              items: pageContextValue?.value ?? '',
            }}
            onClose={handlerCloseIsPageContextM}
            isOpen={isPageContextM}
            alertText={t('targetings.text_copy')}
          />
        )}
        {openAddAjaxListM && (
          <AddTextareaModal
            catalog={settings?.catalog ?? []}
            checkedCount={leftD.filter((item) => item.is_checked).length}
            type={type}
            onHandleSubmit={onChekedLD4arr}
            onHandleGeo={checkAllInArr}
            is_wo_available={settings?.is_wo_available ?? false}
            isOpen={openAddAjaxListM}
            onCloseModal={onCloseAddAjaxListM}
          />
        )}
        <Prompt
          when={showSaveButton}
          message={t('just_words.exit_page_currect')}
        />
        {(error.isError || errorValidateText) && toggle && (
          <TargetItemAlert
            error={error}
            errorValidateText={errorValidateText}
            handlerClose={handleCloseAlertItem}
          />
        )}
      </div>
    </div>
  );
};

export default Item;
