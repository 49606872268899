import React, { FC } from 'react';
import Card from 'components/UI/Card';
import { useUserInfo, useUserTheme } from 'domains/user/model/selectors';
import Help from 'components/Help';
import {
  CampaignGeneralComponentProps,
  RGetPersonal,
} from 'domains/campaign/types';
import { SelectField } from 'types/general';
import { useProjectInfo } from 'domains/project/hooks';
import { useClientInfo } from 'domains/client/model/selectors';
import { useSearchBreadcrumbsInfo } from 'domains/search/model/selectors';
import i18n from '../../../../../../i18n';
import css from '../styles.module.scss';
import FieldsWithCondition from '../Campaign/CampaignFields/FieldsWithCondition';
import { showContractField } from '../Campaign/CampaignFields/utils';

type TFCampaignInGridProps = Pick<
  CampaignGeneralComponentProps,
  'formik' | 'handlerInput' | 'savedField'
> & {
  campaign: RGetPersonal | null;
  type: 'EDIT' | 'CREATE';
  contractsOptions?: SelectField[];
  locked?: boolean;
};

const CampaignInGrid: FC<TFCampaignInGridProps> = ({
  campaign,
  formik,
  type,
  contractsOptions,
  handlerInput,
  savedField,
  locked,
}) => {
  const { getValueFromSettingForKey } = useUserTheme();
  const { data: projectData } = useProjectInfo();
  const { data: breadcrumbs } = useSearchBreadcrumbsInfo();
  const { data: user } = useUserInfo();
  const { data: partner } = useClientInfo();

  if (type === 'EDIT' && !campaign?.campaign_grid?.uuid) {
    return null;
  }

  if (
    type === 'CREATE' &&
    !showContractField({ projectData, partner, breadcrumbs, user })
  ) {
    return null;
  }

  return (
    <Card
      noHover
      style={{ padding: '28px 24px' }}
      className={css.card}
      isUseAccess
    >
      <h3>
        {i18n.t('ord.contracts.campagn_grid')}
        {getValueFromSettingForKey('HideHelp') && (
          <Help
            width={200}
            title={i18n.t('campaigns_page.campaign_settings.Campaign_name')}
            helpattributes={{
              link: i18n.t(
                'campaigns_page.campaign_settings.campaign_prompt.main.link',
              ),
              sizeIcon: 18,
            }}
          />
        )}
      </h3>
      <div className={css.form}>
        <FieldsWithCondition
          campaign={campaign}
          type={type}
          formik={formik}
          contractsOptions={contractsOptions}
          handlerInput={handlerInput}
          savedField={savedField}
          locked={locked}
        />
      </div>
    </Card>
  );
};

export default CampaignInGrid;
