import CollapseWrapper from 'components/CollapseWrapper';
import Created from 'components/GridCompaign/Created';
import Empty from 'components/GridCompaign/Empty';
import Process from 'components/GridCompaign/Process';
import { RGetPersonal } from 'domains/campaign/types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ICampaignInGridProps {
  isCreateGridCampaignLoading: boolean;
  campaign: RGetPersonal | null;
  setCreateCampaignInOrdModal: (value: React.SetStateAction<boolean>) => void;
}

const CampaignInGrid: FC<ICampaignInGridProps> = ({
  isCreateGridCampaignLoading,
  campaign,
  setCreateCampaignInOrdModal,
}) => {
  const { t } = useTranslation();

  return (
    <CollapseWrapper
      title={t(`ord.contracts.campagn_grid`)}
      nameWrapper="CAMPAIDNS_GRID"
      isLoading={isCreateGridCampaignLoading}
    >
      {/* Временно убрали кнопку обновления статуса */}
      {/* {true && (
          <Actions
            onClickAction={() => {
              console.log('test update');
            }}
          />
        )} */}
      {campaign?.campaign_grid?.status === 'NOT_CREATED' && (
        <Empty
          openModal={() => setCreateCampaignInOrdModal(true)}
          text={t(`ord.contracts.campagn_not_created2`)}
          isDisable={!(!campaign.ord_name && !campaign.contract_uuid)}
        />
      )}
      {isCreateGridCampaignLoading && <Process creatingState={false} />}
      {campaign &&
        campaign.campaign_grid &&
        campaign?.campaign_grid?.status !== 'NOT_CREATED' && (
          <Created data={campaign.campaign_grid} />
        )}
    </CollapseWrapper>
  );
};

export default CampaignInGrid;
