import { useEffect, useMemo } from 'react';
import { FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatchApp } from 'redux/rootSelectors';
import { useUserTheme } from 'domains/user/model/selectors';
import { useStorageCollapseWrappersInfo } from 'domains/storage/model/selectors';
import { SagaUpdateVisial } from 'types/general';
import * as Yup from 'yup';
import { CreativeDetail } from 'domains/creative/types';
import { objectOptimizationWithFormik } from '../../../../../../utils/objectOptimizationWithFormik/objectOptimizationWithFormik';
import {
  fetchCreativeTagsRequest,
  setEditCreativeRequest,
} from '../../../../reduser';
import { useCreativeTagsInfo } from '../../../../model/selectors';
import {
  revertCreativeTags,
  updateTagCreative,
} from '../../../../model/actions';
import last from '../../../../../../utils/last';

type CreativeForm = {
  title: CreativeDetail['title'];
  advertiser_name: CreativeDetail['advertiser_name'];
  status: CreativeDetail['status'];
  internal_id: CreativeDetail['internal_id'];
  kktu_codes: CreativeDetail['kktu_codes'];
};

type PUseEditForm = CreativeForm & {
  xxhash: CreativeDetail['xxhash'];
};

type TFUseEditCreativeForm = (props: PUseEditForm) => {
  handlerUpdateTags: (tags: string[]) => void;
  formik: FormikProps<CreativeForm>;
  dataTags: ReturnType<typeof useCreativeTagsInfo>['data'];
  errorTags: ReturnType<typeof useCreativeTagsInfo>['error'];
  LTUPastTags: number;
  LTUTags: number;
  handlerRevertTags: () => void;
  isSavedTags: string[];
};

export const useEditCreativeForm: TFUseEditCreativeForm = ({
  advertiser_name,
  internal_id,
  status,
  title,
  xxhash,
  kktu_codes
}) => {
  const {
    data: tags,
    LTU: LTUTags,
    past: { LTU: LTUPastTags, data: pastTags },
    error: errorTags,
  } = useCreativeTagsInfo();
  const { getValueFromSettingForKey } = useUserTheme();

  const collapseWrappers = useStorageCollapseWrappersInfo();

  const isSavedTags = useMemo<string[]>(() => {
    if (LTUPastTags && pastTags.length && tags) {
      const lastTags = last(pastTags);
      if (lastTags) {
        return tags.filter(
          (value) => lastTags.findIndex((v) => value === v) === -1,
        );
      }
    }
    return [];
  }, [tags, pastTags, LTUPastTags]);

  const dispatch = useDispatchApp();
  const { t } = useTranslation();

  const handlerUpdateTags: ReturnType<TFUseEditCreativeForm>['handlerUpdateTags'] =
    (value) => {
      dispatch(updateTagCreative({ tags: value, xxhash }));
    };

  const handlerRevertTags: ReturnType<TFUseEditCreativeForm>['handlerRevertTags'] =
    () => {
      dispatch(revertCreativeTags({ xxhash }));
    };

  const formik = useFormik<CreativeForm>({
    enableReinitialize: true,
    initialValues: {
      title,
      advertiser_name,
      status,
      internal_id,
      kktu_codes,
    },
    onSubmit: (values) => {
      const param = objectOptimizationWithFormik<CreativeForm>(
        { advertiser_name, internal_id, status, title, kktu_codes },
        values,
      );
      if (param) {
        const visual: SagaUpdateVisial = {
          value: false,
        };
        if (!collapseWrappers.includes('CREATIVE_TRANSLATIONS')) {
          visual.translation =
            getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
            !getValueFromSettingForKey('ShowBroadcastStatus');
        }
        dispatch(
          setEditCreativeRequest({
            data: { xxhash, ...param },
            visual,
          }),
        );
      }
    },
    validationSchema: Yup.object().shape({
      kktu_codes: Yup.string().test(
        'kktu_codes',
        t('errors.err_value'),
        (value) => {
          const regex = /^(\d{1,2}\.\d{1,2}\.\d{1,2})$/g;
          if (value === undefined) return true;
          if (value) {
            return regex.test(value);
          }
          return false;
        },
      ),
      title: Yup.string().required(t('creative.creative_requare')),
    }),
  });

  useEffect(() => {
    if (!tags) {
      dispatch(fetchCreativeTagsRequest({ xxhash }));
    }
  }, []);

  return {
    dataTags: tags,
    handlerUpdateTags,
    formik,
    LTUPastTags,
    isSavedTags,
    errorTags,
    handlerRevertTags,
    LTUTags,
  };
};
