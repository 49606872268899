import React, { FC } from 'react';
import cn from 'classnames';
import { FiX, FiCheckCircle } from 'react-icons/fi';
import { FaRegCopy } from 'react-icons/fa';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import i18n from '../../../../../i18n';
import css from './styles.module.scss';
import { useInput } from './hook';
import ErrorText from '../../../ErrorText';

type InputProps = Parameters<typeof useInput>[0] & {
  maxLength?: number;
  isModeEdit: boolean;
  errorText?: string;
  isError?: boolean;
  isSaved?: boolean;
  isDisable?: boolean;
  placeholder?: string;
  handlerOpenModeEdit: () => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

const Input: FC<InputProps> = ({
  inputValue,
  inputRef,
  setInputValue,
  maxLength,
  isModeEdit,
  isError,
  errorText,
  name,
  isSaved,
  isDisable,
  handlerOpenModeEdit,
  onBlur,
  placeholder,
}) => {
  const { handlerCopyValue, handlerChangeInput, handlerClearInput, copied } =
    useInput({
      setInputValue,
      inputValue,
      name,
      inputRef,
    });

  return (
    <div
      className={cn(css.inputWrapper, 'InputAutoSaveWrapper', {
        isDisable,
        modeView: !isModeEdit,
      })}
    >
      <div
        className={cn(css.label, 'InputAutoSaveLabel', {
          modeView: !isModeEdit,
          isSaved,
        })}
        onClick={handlerOpenModeEdit}
      >
        {inputValue ? (
          <span>{inputValue}</span>
        ) : (
          <span className={css.placeholder}>{placeholder}</span>
        )}
      </div>
      <input
        className={cn(css.input, {
          modeView: !isModeEdit,
          modeEdit: isModeEdit,
          isError,
          isSaved,
        })}
        placeholder={placeholder}
        type="text"
        value={inputValue}
        onChange={handlerChangeInput}
        ref={inputRef}
        maxLength={maxLength}
        onBlur={onBlur}
      />
      {!isModeEdit && inputValue && (
        <div className={css.copy} onClick={handlerCopyValue}>
          {copied && (
            <TooltipPoratal hint={<span>{i18n.t('alerts.copied')}</span>} showConstantly>
              <FiCheckCircle size={10} color="#149c08" />
            </TooltipPoratal>
          )}
          {!copied && <FaRegCopy size={18} />}
        </div>
      )}
      {maxLength && isModeEdit && (
        <div className={css.length}>{`(${
          inputValue ? inputValue?.length : 0
        }/${maxLength})`}</div>
      )}
      {isModeEdit && (
        <div className={css.close} onClick={handlerClearInput}>
          <FiX size={18} />
        </div>
      )}
      {isError && errorText && (
        <div className={css.errorText}>
          <ErrorText text={errorText} />
        </div>
      )}
    </div>
  );
};

export default Input;
