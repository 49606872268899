import React, { ChangeEvent, ChangeEventHandler, FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Loader from 'components/UI/Loader';
import SelectV2 from 'components/UI/SelectV2';
import {} from 'domains/campaign/Settings/hooks';
import Help from 'components/Help';
import PriceInput from 'components/UI/PriceInput';
import Input from 'components/UI/Input/input';
import cn from 'classnames';
import { ListSRMethods, TExperimentalInfoField } from 'domains/campaign/types';
import EditCard from 'components/UI/EditCard';
import { checkedLocalization } from 'utils/checkedLocalization';
import {
  isDisableSelfService,
  TDataType,
} from 'utils/MethodRecalcHelpers/utils';
import ToggleSwitch from 'components/UI/ToggleSwitch';
import css from './styles.module.scss';

interface Params {
  isLoadingData: boolean;
  formik: any;
  SRMethod: string;
  listSRMethods: ListSRMethods | null;
  onChangeSRMethod: (v: string | number) => void;
  commissionAgencyData: TExperimentalInfoField;
  commissionSelfServiceData: TExperimentalInfoField;
  cpcData: TExperimentalInfoField | undefined;
  marginalityData: TExperimentalInfoField | undefined;
  data: TDataType;
}

const CalculationCoefficientsBlock: FC<Params> = ({
  isLoadingData,
  formik,
  SRMethod,
  listSRMethods,
  onChangeSRMethod,
  commissionAgencyData,
  commissionSelfServiceData,
  cpcData,
  marginalityData,
  data,
}) => {
  const { t } = useTranslation();
  const isDisableSelf = isDisableSelfService(data);
  const [edited, setEdited] = useState(false);

  const onKeyHandler = (keyboardEvent: React.KeyboardEvent) => {
    const { keyCode } = keyboardEvent;
    if (keyCode === 13) {
      keyboardEvent.preventDefault();
    }
  };

  // eslint-disable-next-line consistent-return
  const handlerAdditionalMetrics: ChangeEventHandler<HTMLInputElement> = (
    e,
    // eslint-disable-next-line consistent-return
  ) => {
    const { value } = e.target;
    const pattern = /^[0-9\s.\n]*$/;
    if (pattern.test(value)) {
      return formik.setFieldValue('fields.additional_metrics', value);
    }
  };

  const submitForm = () => {
    formik.submitForm();
    setEdited(false);
  };

  const resetForm = () => {
    setEdited(false);
    formik.resetForm();
    onChangeSRMethod(formik.values.method_recalculating_statistics);
  };

  return (
    <EditCard
      noHover
      edited={edited}
      onSave={submitForm}
      setEdit={() => setEdited(!edited)}
      onResetForm={resetForm}
      hasErrors={!!Object.keys(formik.errors).length}
    >
      {isLoadingData ? (
        <Loader />
      ) : (
        <>
          <h3 className={css.card_title}>
            {t(`campaigns_page.campaign_settings.additional.method`)}
          </h3>
          <div
            className={cn(css.form, {
              _isNoEditing: edited && isDisableSelf,
            })}
          >
            <SelectV2
              value={SRMethod}
              label={t('campaigns_page.campaign_settings.ListSRMethods.label')}
              placeholder={t(
                'campaigns_page.campaign_settings.additional.ddmm',
              )}
              disabled={!edited}
              options={listSRMethods || []}
              customChange={onChangeSRMethod}
            />
            {cpcData && SRMethod === 'MAX_FIX_CPC_MARGINALITY' && (
              <PriceInput
                classname={css.number_format}
                decimalScale={0}
                theme="horizontal"
                label={checkedLocalization(
                  `campaigns_page.campaign_settings.ListSRMethods.${cpcData.field}.label`,
                  cpcData.title,
                )}
                hasZero
                error={!!formik.errors[cpcData.id]}
                errortext={formik.errors[cpcData.id]}
                value={
                  formik.values.fields.config_values &&
                  formik.values.fields.config_values[cpcData.id]
                }
                disabled={!edited}
                onKeyDown={onKeyHandler}
                onValueChange={({ floatValue }) => {
                  formik.setFieldValue(
                    `fields.config_values.${cpcData.id}`,
                    floatValue,
                  );
                }}
              />
            )}
            {SRMethod === 'SELF_SERVICE_MARKUP' && (
              <PriceInput
                classname={css.number_format}
                decimalScale={3}
                theme="horizontal"
                label={checkedLocalization(
                  `campaigns_page.campaign_settings.ListSRMethods.${commissionAgencyData.field}.label`,
                  commissionAgencyData.title,
                )}
                disabled={!edited}
                error={!!formik.errors.fields?.commission_agency}
                errortext={formik.errors.fields?.commission_agency}
                value={formik.values.fields.commission_agency}
                onKeyDown={onKeyHandler}
                onValueChange={({ floatValue }) => {
                  formik.setFieldValue(`fields.commission_agency`, floatValue);
                }}
              />
            )}
            {SRMethod === 'SELF_SERVICE_MARKUP' && (
              <PriceInput
                classname={css.number_format}
                decimalScale={3}
                theme="horizontal"
                label={checkedLocalization(
                  `campaigns_page.campaign_settings.ListSRMethods.${commissionSelfServiceData.field}.label`,
                  commissionSelfServiceData.title,
                )}
                hasZero
                disabled={!edited}
                error={!!formik.errors.fields?.commission_self_service}
                errortext={formik.errors.fields?.commission_self_service}
                value={formik.values.fields.commission_self_service}
                onKeyDown={onKeyHandler}
                onValueChange={({ floatValue }) => {
                  formik.setFieldValue(
                    `fields.commission_self_service`,
                    floatValue,
                  );
                }}
              />
            )}
            {marginalityData && SRMethod === 'MAX_FIX_CPC_MARGINALITY' && (
              <PriceInput
                classname={css.number_format}
                decimalScale={3}
                theme="horizontal"
                label={checkedLocalization(
                  `campaigns_page.campaign_settings.ListSRMethods.${marginalityData.field}.label`,
                  marginalityData.title,
                )}
                hasZero
                disabled={!edited}
                error={!!formik.errors[marginalityData.id]}
                errortext={formik.errors[marginalityData.id]}
                value={
                  formik.values.fields.config_values &&
                  formik.values.fields.config_values[marginalityData.id]
                }
                onKeyDown={onKeyHandler}
                onValueChange={({ floatValue }) => {
                  formik.setFieldValue(
                    `fields.config_values.${marginalityData.id}`,
                    floatValue,
                  );
                }}
              />
            )}
            {SRMethod === 'MAX_FIX_CPC_MARGINALITY' && (
              <div className={css.field_item}>
                <PriceInput
                  decimalScale={3}
                  theme="horizontal"
                  label={t(
                    'campaigns_page.campaign_settings.ListSRMethods.hidden_coef.label',
                  )}
                  hasZero
                  disabled={!edited}
                  value={formik.values.fields.hidden_commissions}
                  onKeyDown={onKeyHandler}
                  error={!!formik.errors.fields?.hidden_commissions}
                  errortext={formik.errors.fields?.hidden_commissions}
                  onValueChange={({ floatValue }) => {
                    formik.setFieldValue(
                      'fields.hidden_commissions',
                      floatValue,
                    );
                  }}
                />
                <Help
                  title=""
                  helpattributes={{
                    text: `Процент скрытой комиссии. Влияет на расчет маржи \nMargin= (spent - minus_win_raw- spent*СК(в процентах))`,
                  }}
                />
              </div>
            )}
            {SRMethod === 'COEFFICIENTS' && (
              <Input
                label={t(
                  'campaigns_page.campaign_settings.additional.coefficient',
                )}
                error={!!formik.errors.fields?.statistic_coefficient}
                errortext={formik.errors.fields?.statistic_coefficient}
                isTextarea
                inputAttributes={{
                  type: 'textarea',
                  disabled: !edited,
                  name: 'statistic_coefficient',
                  // eslint-disable-next-line consistent-return
                  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => {
                    const { value } = e.target;
                    const pattern = /^[0-9\s.\n]*$/;
                    if (pattern.test(value)) {
                      return formik.setFieldValue(
                        'fields.statistic_coefficient',
                        value,
                      );
                    }
                  },
                  placeholder: t(
                    'campaigns_page.campaign_settings.ListSRMethods.enter_data',
                  ),
                  value: formik.values.fields.statistic_coefficient,
                  style: { minHeight: 256 },
                }}
                tooltip={{
                  text: (
                    <Trans
                      i18nKey="campaigns_page.campaign_settings.click_hint.general"
                      components={{ br: <br />, b: <strong /> }}
                    />
                  ),
                }}
              />
            )}
            {SRMethod === 'CAMPAIGN_TYPE' && (
              <div className={css.rows}>
                <div
                  className={cn(css.period, {
                    _isErrors:
                      (!!formik.errors.from && !!formik.touched.from) ||
                      (!!formik.errors.from && !!formik.touched.from),
                  })}
                >
                  <div className={css.label_row}>
                    {t(`campaigns_page.campaign_settings.additional.range`)} *
                  </div>
                  <div className={css.label_field}>
                    {t(`campaigns_page.campaign_settings.additional.from`)}
                  </div>
                  <PriceInput
                    classname={css.number_format}
                    formik={formik}
                    decimalScale={3}
                    theme="horizontal"
                    disabled={!edited}
                    errorAbsolte
                    allowNegative
                    error={!!formik.errors.from && !!formik.touched.from}
                    errortext={formik.errors.from}
                    value={formik.values.from}
                    handleBlur={() => formik.setFieldTouched('from', true)}
                    onValueChange={({ floatValue }) => {
                      formik.setFieldValue(`from`, floatValue);
                    }}
                  />
                  <div className={css.label_field}>
                    {t(`campaigns_page.campaign_settings.additional.to`)}
                  </div>
                  <PriceInput
                    disabled={!edited}
                    classname={css.number_format}
                    formik={formik}
                    decimalScale={3}
                    theme="horizontal"
                    allowNegative
                    errorAbsolte
                    error={!!formik.errors.to && !!formik.touched.to}
                    errortext={formik.errors.to}
                    value={formik.values.to}
                    handleBlur={() => formik.setFieldTouched('to', true)}
                    onValueChange={({ floatValue }) => {
                      formik.setFieldValue(`to`, floatValue);
                    }}
                  />
                </div>
              </div>
            )}
            {SRMethod === 'CAMPAIGN_TYPE' && (
              <Input
                label={t('campaigns_page.campaign_settings.additional.clicks')}
                isTextarea
                error={!!formik.errors.fields?.additional_metrics}
                errortext={formik.errors.fields?.additional_metrics}
                inputAttributes={{
                  name: 'additional_metrics',
                  placeholder: t(
                    'campaigns_page.campaign_settings.enter_data_tip',
                  ),
                  onChange: handlerAdditionalMetrics,
                  style: { minHeight: 246 },
                  value: formik.values.fields.additional_metrics,
                  disabled: !edited,
                }}
                tooltip={{
                  text: (
                    <Trans
                      i18nKey="campaigns_page.campaign_settings.click_hint.camp"
                      components={{ br: <br />, b: <strong /> }}
                    />
                  ),
                }}
              />
            )}
            <ToggleSwitch
              checked={formik.values.ord_registration_creatives}
              activateText={t(`ord.reg_creo`)}
              deactivateText={t(`ord.reg_creo`)}
              onChange={() => {
                formik.setFieldValue(
                  `ord_registration_creatives`,
                  Boolean(!formik.values.ord_registration_creatives),
                );
              }}
              disabled={!edited}
            />
          </div>
        </>
      )}
    </EditCard>
  );
};

export default CalculationCoefficientsBlock;
