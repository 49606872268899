import React, { useRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDropDown from 'hooks/useDropDown';
import cn from 'classnames';
import { FiMoreHorizontal } from 'react-icons/fi';
import { checkedLocalization } from 'utils/checkedLocalization';
import css from './styles.module.scss';
import ModalWithQuestion from '../../modals/modalWithQuestion';
import { TargetKey } from '../model/types';

export interface ListOptionsProps {
  disabled?: boolean;
  className?: string;
  /** тип таргета */
  type: TargetKey;
  /** Внешний вид таргета */
  view: number;
  /** открыть модал */
  openModal: (isListMessage?: boolean) => void;
  /** сбрасывает добавленые/удаленные в таблицу поля */
  setMiddeleData?: (clear: boolean) => void;
  clearAll?: (reCreate?: boolean) => void;
  /** установить Режим инвертирования. */
  setIsInvertMode: (v: boolean) => void;
  /** Креатив или кампания */
  isCreative: boolean;
  /** В компании */
  isCompain: boolean;
  /** используются ли настройки кампании */
  useSettings: boolean;
  /** функция изменение получения настроек кампании */
  setUseSettings: (p: boolean) => void;
  /** state показа кнопок сохранения */
  setShowSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
  showHintEvent: () => void;
  /** обновление справочника(каталога) */
  isUpdateTarget?: boolean;
  /* не показываем для source и geo основные пункты, кроме скачать */
  exceptions: boolean;
  /** Обновление каталога */
  updateCatalogHandler?: () => void;
  onGetReport: () => void;
  disGetReport: boolean;
  showDownload: boolean;
}

const ListOptions: React.FC<ListOptionsProps> = ({
  disabled,
  className,
  type,
  view,
  setMiddeleData,
  clearAll,
  openModal,
  setIsInvertMode,
  useSettings,
  isUpdateTarget,
  isCreative,
  isCompain,
  updateCatalogHandler,
  setUseSettings,
  setShowSaveButton,
  showHintEvent,
  exceptions,
  onGetReport,
  disGetReport,
  showDownload,
}) => {
  const [questionModal, setQuestionModal] = useState<boolean>(false);
  const dropdownMenu = useRef<HTMLDivElement | null>(null);
  const { toggle, isOpened, toggleOff } = useDropDown(dropdownMenu);
  const typesForAddList: TargetKey[] = ['geo_id', 'au_self'];

  const onClick = useCallback(() => {
    if (!useSettings) {
      showHintEvent();
      return;
    }
    toggleOff();
    openModal();
  }, [useSettings]);

  const onClickTextList = useCallback(() => {
    if (!useSettings) {
      showHintEvent();
      return;
    }
    toggleOff();
    openModal(true);
  }, [useSettings]);

  // reCreate флаг для пересоздания таргета после очищения, если юзер не хочет включать наследование от рк
  const onClear = useCallback(
    (reCreate?: boolean) => {
      if (setMiddeleData) {
        setMiddeleData(true);
      }
      if (clearAll) {
        clearAll(reCreate);
      }

      setIsInvertMode(false);
      setShowSaveButton(false);
      toggleOff();
      if (isCreative || isCompain) {
        setQuestionModal(false);
      }
    },
    [setMiddeleData, clearAll],
  );

  const onClearHandler = () => {
    if (!useSettings) {
      showHintEvent();
      return;
    }
    if (isCreative || isCompain) {
      setQuestionModal(true);
      return;
    }
    onClear();
  };

  const { t } = useTranslation();

  return (
    <div
      className={cn(css.options, className, {
        isToggle: toggle,
        isOpened,
        isDisabled: disabled,
      })}
      ref={dropdownMenu}
    >
      <div
        className={css.active}
        onClick={disabled || isOpened ? toggleOff : toggle}
      >
        <FiMoreHorizontal size={20} color="#828282" />
      </div>
      <div className={cn(css.list, { isOpened })}>
        {isUpdateTarget && type !== 'au' && (
          <div
            className={cn(css.item, {
              _isDisable: false,
            })}
            onClick={updateCatalogHandler}
          >
            {t('targetings.update_guide')}
          </div>
        )}
        {showDownload && (
          <div
            className={cn(css.item, {
              _isDisable: disGetReport,
            })}
            onClick={onGetReport}
          >
            {t('targetings.down_stat')}
          </div>
        )}
        {exceptions && (
          <>
            {[2, 3, 4].includes(view) && type !== 'geo_id' && (
              <div className={cn(css.item)} onClick={onClick}>
                {checkedLocalization(
                  `targetings.add_popap_tit.${type}`,
                  `targetings.add_list`,
                )}
              </div>
            )}
            {typesForAddList.includes(type) && (
              <div className={cn(css.item)} onClick={onClickTextList}>
                {t('targetings.add_list')}
              </div>
            )}
            <div className={cn(css.item)} onClick={onClearHandler}>
              {t('targetings.clear_target')}
            </div>
          </>
        )}
      </div>
      {questionModal && isCreative && (
        <ModalWithQuestion
          onClose={() => setQuestionModal(false)}
          onClick={() => {
            setUseSettings(!useSettings);
            onClear();
            setQuestionModal(false);
          }}
          onClickNegative={() => onClear(true)}
          subText={t('targetings.use_set_modal_creo')}
          modalName={t('just_words.confirm_action')}
          btnTitle={t('yes')}
          btnCloseTitle={t('no')}
        />
      )}
      {questionModal && isCompain && (
        <ModalWithQuestion
          onClose={() => setQuestionModal(false)}
          onClick={() => {
            setUseSettings(!useSettings);
            onClear();
            setQuestionModal(false);
          }}
          onClickNegative={() => setQuestionModal(false)}
          subText={t(`targetings.reset_target_settings`)}
          modalName={t('just_words.confirm_action')}
          btnTitle={t('yes')}
          btnCloseTitle={t('no')}
        />
      )}
    </div>
  );
};

export default ListOptions;
