import { ResponseInfoClient } from 'domains/client/types';
import { RProject } from 'domains/project/types';
import { RBreadCrumbs } from 'domains/search/model/types';
import { ResponseUser } from 'domains/user/types';
import { FormikProps } from 'formik';
import { someGroups } from 'utils/statics/everyGroups';

type THelpersProps = {
  projectData: RProject | null;
  partner: ResponseInfoClient | null;
  breadcrumbs: RBreadCrumbs | null;
  user: ResponseUser | null;
  isOwner: boolean;
  formik: FormikProps<any>;
};

export const showContractField = ({
  projectData,
  partner,
  breadcrumbs,
  user,
}: Omit<THelpersProps, 'formik' | 'isOwner'>): boolean => {
  if (projectData && partner && breadcrumbs?.Client) {
    return (
      user?.partner.type !== 'CLIENT' &&
      someGroups(user, 'self_user', 'owner') &&
      projectData?.ord_registration &&
      !!partner.ord_registration_creatives
    );
  }
  return false;
};

export const showKktuField = ({
  user,
  partner,
}: Pick<THelpersProps, 'user' | 'partner'>): boolean => {
  if (
    someGroups(user, 'owner', 'self_user') &&
    !!partner?.ord_registration_creatives
  )
    return true;

  return false;
};

export const showOrdNameField = ({
  projectData,
  partner,
  breadcrumbs,
  isOwner,
  formik,
  user,
}: THelpersProps): boolean => {
  if (projectData && partner && breadcrumbs?.Client) {
    return !!(
      user?.partner.type !== 'CLIENT' &&
      isOwner &&
      projectData?.ord_registration &&
      partner.ord_registration_creatives &&
      formik.values.contract_uuid
    );
  }
  return false;
};
