import { FormikProps, useFormik } from 'formik';
import { OptionTypeBase } from 'components/UI/NewSelect';
import { contractsOptionsFunc } from 'domains/campaign/ui/Main/utils';
import { useContractsList } from 'domains/contracts/model/selectors';
import { SelectField, TID } from 'types/general';
import { useDispatchApp } from 'redux/rootSelectors';
import {
  fetchCreateCampaignGrid,
  fetchGridCampaignForContract,
  fetchPersonalData,
} from 'domains/campaign/model/actions';
import { RGetPersonal, TCreateCampaignGrid } from 'domains/campaign/types';
import { useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useGridCampaignForContract } from 'domains/campaign/model/selectors';
import { PropsValue } from 'react-select';
import { validationSchema } from './validation';
import i18n from '../../../../../../i18n';

export type TInitialState = {
  contract_uuid: string;
  ord_name: string;
  kktu_codes: string;
};

export type TReturnCampaignInGrid = (
  campaign: RGetPersonal | null,
  setIsCreateGridCampaignLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setCreateCampaignInOrdModal: (value: React.SetStateAction<boolean>) => void,
  setNameCampaignInOrd: React.Dispatch<
    React.SetStateAction<OptionTypeBase[] | null>
  >,
  update: boolean,
) => {
  formik: FormikProps<TInitialState>;
  ordNameSelectOptions: OptionTypeBase[];
  contractsOptions: SelectField[];
  // nameCampaignInOrd: OptionTypeBase[] | null;
  isGridCampaignForContractLoading: boolean;
  defaultOrdNameType: PropsValue<OptionTypeBase>;
  setDefaultOrdNameType: React.Dispatch<
    React.SetStateAction<PropsValue<OptionTypeBase>>
  >;
  onCloseHandler: () => void;
  onCreateHandler: () => void;
  isEmpty: (obj: any) => boolean;
};

export const useCampaignInGrid: TReturnCampaignInGrid = (
  campaign,
  setIsCreateGridCampaignLoading,
  setCreateCampaignInOrdModal,
  setNameCampaignInOrd,
  update
) => {
  const ordNameSelectOptions = useMemo(
    () => [
      {
        label: i18n.t('select_btn'),
        value: 'pick',
      },
      {
        label: i18n.t('create_btn'),
        value: 'create',
      },
    ],
    [i18n.language],
  );

  const {
    params: { id },
  } = useRouteMatch<TID>();

  const dispatch = useDispatchApp();

  const { data: contractsList } = useContractsList();

  const contractsOptions = useMemo(
    () => contractsOptionsFunc(contractsList),
    [contractsList],
  );

  const {
    data: gridCampaignForContract,
    isLoading: isGridCampaignForContractLoading,
  } = useGridCampaignForContract();

  const [defaultOrdNameType, setDefaultOrdNameType] = useState<
    PropsValue<OptionTypeBase>
  >(ordNameSelectOptions[1]);

  const onCloseHandler = () => {
    formik.resetForm();
    setCreateCampaignInOrdModal(false);
  };

  const onCreateHandler = () => {
    formik.submitForm();
    setCreateCampaignInOrdModal(false);
  };

  const isEmpty = (obj: any) => Object.keys(obj).length === 0;

  const getCampaignInfo = () => {
    dispatch(fetchPersonalData(id));
  };

  const formik = useFormik<TInitialState>({
    enableReinitialize: true,
    initialValues: {
      contract_uuid: '',
      ord_name: '',
      kktu_codes: '',
    },
    onSubmit: (values) => {
      if (campaign) {
        const params: TCreateCampaignGrid = {
          ...values,
          xxhash: campaign?.xxhash,
          date_start: campaign?.date_start.date,
          date_end: campaign?.date_end.date,
          callback: {
            setIsCreateGridCampaignLoading,
            getCampaignInfo,
          },
        };

        dispatch(fetchCreateCampaignGrid(params));
      }
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: false,
  });

  useEffect(() => {
    if (gridCampaignForContract && update) {
      const data = gridCampaignForContract.map((obj) => ({
        label: obj.ord_name,
        value: obj.uuid,
        kktu_codes: obj.kktu_codes,
      }));
      setNameCampaignInOrd(data);
    }
  }, [gridCampaignForContract, update]);

  useEffect(() => {
    if (formik.values.contract_uuid) {
      dispatch(
        fetchGridCampaignForContract({
          status: ['ACTIVE'],
          contract_uuid: formik.values.contract_uuid,
        }),
      );
    }
  }, [formik.values.contract_uuid]);

  return {
    formik,
    ordNameSelectOptions,
    contractsOptions,
    isGridCampaignForContractLoading,
    defaultOrdNameType,
    setDefaultOrdNameType,
    onCloseHandler,
    onCreateHandler,
    isEmpty,
  };
};
